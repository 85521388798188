import React from "react";
import {CtaReturn} from "../../other/Cta";

export const OtherSingleItemHeader = ({singleItem}) => {

    return (
        <div className={"other_single_item_header"}>

            <div className={"picture"}/>

            <div className={"content"}>
                <div className={"title"}>{singleItem.title}</div>
                {singleItem.type &&
                    <div className={"other_single_item_data"}>
                        <label>Type</label>
                        <div>{singleItem.type.name}</div>
                    </div>
                }
                {singleItem.court &&
                    <div className={"other_single_item_data"}>
                        <label>Tribunal</label>
                        <div>{singleItem.court.name}</div>
                    </div>
                }
                <div className={"other_single_item_data"}>
                    <label>Lieu</label>
                    <div>{"NA"}</div>
                </div>
                {singleItem.date &&
                    <div className={"other_single_item_data"}>
                        <label>Date</label>
                        <div>{new Date(singleItem.date).toLocaleDateString("fr-FR")}</div>
                    </div>
                }
                {singleItem.bar &&
                    <div className={"other_single_item_data"}>
                        <label>Barreau actuel</label>
                        <div>{singleItem.bar.name}</div>
                    </div>
                }
                <div className={"other_single_item_data description"}>
                    <label>Description</label>
                    <div>{singleItem.description}</div>
                </div>
            </div>

        </div>
    )
}

const SingleItemHeader = ({singleItem, setShowSlidingPanelDetails}) => {
    return (
        <div className={"single_item_header"}>

            <div className={"cta_wrapper"}>
                <CtaReturn/>
            </div>

            <div className={"picture"}/>

            <div className={"content"}>
                <div className={"title"}>{singleItem.title}</div>
                <div className={"description"}>{singleItem.type?.name}</div>
                <div className={"other"}>
                    <a className={"detail"} onClick={() => setShowSlidingPanelDetails(true)}>Détails</a>
                    <div className={"date"}>{new Date(singleItem.createdOn).toLocaleDateString("fr-FR")}</div>
                </div>
            </div>

        </div>
    )
}

export default SingleItemHeader;