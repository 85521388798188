import {STEP_AGREEMENT, STEP_DONE, STEP_PENDING, STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED} from "../other/Constant";

/**
 *
 * @param missions
 * @returns {{draft: *[], closed: *[], published: *[]}}
 */
export const sortMissionByStatus = (missions) => {

    let missions_currents = [];
    let missions_draft = [];
    let missions_closed = [];

    missions.forEach( m => {

        if(m.published && !m.closed)
            missions_currents.push(m)
        else if(m.published && m.closed)
            missions_closed.push(m)
        else
            missions_draft.push(m)
    })

    return {
        published : missions_currents,
        closed : missions_closed,
        draft : missions_draft
    }
}

export const sortMissionCandidatByStatus = (missions) => {

    let missions_interest = [];
    let missions_discuss = [];
    let missions_agreement = [];
    let missions_closed = [];

    missions.forEach( m => {

        console.log("match details", m)

        if(m.mission.closed)
            missions_closed.push(m)
        else{
            switch(m.step){
                case STEP_RECIPIENT_ACCEPTED : missions_interest.push(m); break;
                case STEP_SENDER_ACCEPTED : missions_discuss.push(m); break;
                case STEP_AGREEMENT : missions_agreement.push(m); break;
                case STEP_DONE : missions_closed.push(m); break;
            }
        }
    })

    return {
        interest : missions_interest,
        closed : missions_closed,
        discuss : missions_discuss,
        agreement : missions_agreement,
    }
}


export const filterDuplicateObjectInLinkings = (objects, orderByStep = false) => {
    let filtered = []
    let helps_ids = []

    objects.forEach((h) => {

        if (!h.help) {
            return
        }
        if(helps_ids.includes(h.help.id))
            return

        filtered.push(h.help)
        helps_ids.push(h.help.id)
    })

    if(orderByStep)
        filtered.sort((a, b) => b.step - a.step);

    return filtered
}