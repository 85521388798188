import React, {Suspense, useContext, useEffect, useState} from "react";
import Slider from "react-slick";

import SlidingPanel from "./_sliding_panel";
import FilterButtonBar from "../other/FilterButtonBar";
import {Await, useLoaderData, useNavigate, useOutletContext} from "react-router-dom";
import {GenericEmptyItem} from "../other/GenericBlock";
import Api from "../helper/api";
import {AuthContext} from "../userAuth/AuthContext";

const NotificationItem = ({notification, onClickCallback, deleteCallBack, itemId}) => {
    const navigate = useNavigate()

    return(
        <div className={`notification_item ${notification.read === false ? 'not-read': ''}`} onClick={() => {
            onClickCallback()
        }}>
            {/*<div className={"picture"}/>*/}
            <div className={"content"}>
                <div className={"title"}>{notification.title}</div>
                <div className={"description"}>{notification.body}</div>
                <div className={"date"}>{new Date(notification.createdOn).toLocaleDateString("fr-FR")}</div>
            </div>
            <div className={"actions"}>
                <button className={"cta delete"} onClick={(e) => {
                    e.preventDefault()
                    deleteCallBack()
                }}></button>
            </div>
        </div>
    )
}

const Notifications = () => {

    const [filterValue, setFilterValue] = useState(0);
    const [showSlidingPanel, setShowSlidingPanel] = useState(false);

    const [missionNotifications, setMissionNotification] = useState([])
    const [communityNotifications, setCommunityNotification] = useState([])

    const { notifications } = useLoaderData()
    const {user} = useOutletContext();

    const api = new Api();

    const navigate = useNavigate()
    const {state: ContextState, setNewNotifications} = useContext(AuthContext)

    const deleteNotification = (item, type) => {
        console.log("DELETE NOTIF")
        api.deleteNotification(item.id).then(response => {
            if (response.ok) {
                if (type === 0) {
                    setMissionNotification(missionNotifications.filter(obj => obj.id !== item.id))
                } else if (type === 1) {
                    setCommunityNotification(communityNotifications.filter(obj => obj.id !== item.id))
                }
            }
        })
    }

    const updateNotification = (item) => {
        const data = {
            read: true
        }
        console.log(item.group, "NOTIFICATION GROUP")
        console.log(item.type, "NOTIFICATION TYPE")
        api.patchNotification(item.id, data).then(response => {
            if (item.group === 0) {
                setMissionNotification(missionNotifications.map(obj => {
                    if (obj.id === item.id) {
                        obj.read = true
                    }
                    return obj;
                }))
                if (item.type === 0) {
                    navigate(`/missions/${item.entity}`)
                } else if (item.type === 9) {
                    navigate("/matchs/mission/" + item.entity)
                } else if (item.type === 1) {
                    // navigate("/matchs/mission/" + item.entity)
                }
            } else if (item.group === 1) {
                setCommunityNotification(communityNotifications.map(obj => {
                    if (obj.id === item.id) {
                        obj.read = true
                    }
                    return obj;
                }))
                navigate(`/helps/${item.entity}`)
            // } else if (item.group === 2) {
            //     setSwitchDuties(switchDuties.map(obj => {
            //         if (obj.id === item.id) {
            //             obj.read = true
            //         }
            //         return obj;
            //     }))
            //     loadSwitchDuty(item.entity)
            }
        })
    }

    useEffect(() => {
        notifications.then((notifications) => {

            const mission_notifications = []
            const community_notifications = []

            notifications.forEach((n) => {
                if (!ContextState.newNotifications) {
                    setNewNotifications(true)
                }
                const notificationItem = <NotificationItem notification={n} itemId={n.id} onClickCallback={() => { updateNotification(n)}} deleteCallBack={() => {
                    deleteNotification(n, n.group)
                }}/>

                switch(n.group){
                    case 0 :
                        mission_notifications.push(notificationItem)
                        break;
                    case 1 :
                        community_notifications.push(notificationItem)
                        break;
                }
            })

            setMissionNotification(mission_notifications)
            setCommunityNotification(community_notifications)
        })
    }, [])

    return(
        <div id={"notifications_wrapper"}>

            <div className={"bloc_title_info"}>
                <h2>Notifications</h2>
                <p>Retrouvez ici toutes vos notifications de missions, aides et autres</p>
            </div>


            <FilterButtonBar filters={[
                { value : "missions", label : `Missions (${missionNotifications.length})`},
                { value : "community", label : `Communauté (${communityNotifications.length})`},
            ]} setFilterValue={setFilterValue}/>

            {filterValue === 0 &&
                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={notifications} errorElement={<p>Error loading Data !</p>} >
                        <div className={"notification_block_wrapper"}>
                            {missionNotifications.length > 0 && missionNotifications}
                            {missionNotifications.length <= 0 && <GenericEmptyItem title={"Aucune notification pour le moment"}/>}
                        </div>
                    </Await>
                </Suspense>
            }

            {filterValue === 1 &&
                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={notifications} errorElement={<p>Error loading Data !</p>} >
                        <div className={"notification_block_wrapper"}>
                            {communityNotifications.length > 0 && communityNotifications}
                            {communityNotifications.length <= 0 && <GenericEmptyItem title={"Aucune notification pour le moment"}/>}
                        </div>
                    </Await>
                </Suspense>
            }

            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)}>
            </SlidingPanel>

        </div>
    )
}

export default Notifications