import Api from "../helper/api";

/**
 * Sitchs duty loaders
 * @param context
 * @returns {Promise<{userApplications: Promise<*>, userHelps: Promise<[]|*>, helps: Promise<[]|*>, urgentHelps: Promise<[]|*>}>}
 */
export const getSwitchs = async (context) => {

    const api = new Api();
    let switchsPromise = api.getUserSwitchDuties(true,false, null);
    let urgentSwitchsPromise = api.getUserSwitchDuties(true,false, true);
    let userSwitchsPromise = api.getUserSwitchDuties(true,false, null, context.state.user.id);
    let userApplicationsPromise = api.getSwitchLinkings(null, [1, 3, 4]);

    return {
        switchs : switchsPromise,
        urgentSwitchs : urgentSwitchsPromise,
        userSwitchs : userSwitchsPromise,
        userApplications : userApplicationsPromise,
        allDones: []
    };
}

/**
 * Return data for current switch
 * @param params - contain switchId
 */
export const getSwitch = async ({params}) => {

    const api = new Api()
    const switchPromise = api.getSwitch(params.switchId)

    return {
        switchPromise : switchPromise
    }
}

/**
 * Return Help linking List for a specific mission
 * @param params - contain missionId
 */
/*export const getHelpMatchs = async ({params}) => {

    const api = new Api()
    const matchsPromise = api.getHelpLinkings(params.helpId, null, true)

    return {
        type : "aide",
        matchs : matchsPromise
    }
}*/