import Api from "../helper/api";
import {createContext, useContext, useEffect, useState} from "react";
import { Client } from "twilio-chat";

const TwilioContext = createContext();

export const TwilioProvider = ({ isLoggedIn, children }) => {

    const api = new Api()

    const [chatClient, setChatClient] = useState(null);
    const [conversations, setConversations] = useState([]);

    //on component mount
    useEffect(() => {
        if(isLoggedIn)
            initTwilio()

    }, [isLoggedIn]);

    useEffect(() => {
        fetchConversations()
    },[chatClient])

    const initTwilio = async () => {

        //get token from ester api
        const token = await api.getTwilioToken()

        //init twilio with token
        const client = await new Client(token.token);
        setChatClient(client)

        client.on("tokenAboutToExpire", async () => {
            const token = await api.getTwilioToken();
            client.updateToken(token.token);
        });

        client.on("tokenExpired", async () => {
            const token = await api.getTwilioToken();
            client.updateToken(token.token);
        });

        // client.on("conversationAdded", fetchConversations);
        client.on("messageAdded", fetchConversations);
    }

    const fetchConversations = () => {
        if (chatClient) {
            try {
                //fetch conversations
                chatClient.getSubscribedChannels().then(async (paginator) => {
                    if (paginator && paginator.items.length > 0) {
                        const conversationsWithMessages = await Promise.all(
                            paginator.items.map(async (channel) => {
                                channel.messages = await channel.getMessages();
                                return channel
                            })
                        );
                        setConversations(conversationsWithMessages);
                    }
                })
                //fetch messages
                // const conversationsWithMessages = await Promise.all(
                //     subscribedChannels.items.map(async (channel) => {
                //         channel.messages = await channel.getMessages();
                //         return channel
                //     })
                // );
                // setConversations(conversationsWithMessages);
            } catch (error) {
                console.error('Error fetching conversations:', error);
            }
        }
    };

    const sendMessage = async (channelId, message) => {
        try {
            const channel = await chatClient.getChannelBySid(channelId);
            await channel.sendMessage(message);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return(
        <TwilioContext.Provider value={{chatClient, conversations, sendMessage}}>
            {children}
        </TwilioContext.Provider>
    )

}
export const useChat = () => useContext(TwilioContext);
