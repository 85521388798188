import Api from "../helper/api";

/**
 * Sitchs duty loaders
 * @param context
 * @returns {Promise<{userApplications: Promise<*>, userHelps: Promise<[]|*>, helps: Promise<[]|*>, urgentHelps: Promise<[]|*>}>}
 */
export const getQuestions = async (context) => {

    const api = new Api();
    let questionsPromise = api.getQuestions();

    return {
        questions : questionsPromise
    };
}

export const getActs = async (context) => {

    const api = new Api();
    let actsPromise = api.getActs();

    return {
        acts : actsPromise
    };
}

export const getCommunity = async (context) => {

    const api = new Api();
    let questionsPromise = api.getQuestions();
    let actsPromise = api.getActs();

    return Promise.all([
        questionsPromise,
        actsPromise
    ])
}

/**
 * Return data for current switch
 * @param params - contain switchId
 */
export const getQuestion = async ({params}) => {

    const api = new Api()
    const questionPromise = api.getQuestion(params.questionId)

    return {
        question : questionPromise
    }
}

export const getMyQuestions = async (context) => {

    const api = new Api();
    let questionsPromise = api.getQuestions({user : context.state.user.id});

    return {
        questions : questionsPromise
    };
}