import React from "react";
import { FileUploader } from "react-drag-drop-files";

const AddModelAct = () => {

    return (
        <div id={"addmodelact_wrapper"}>
            <div className={"bloc_title_info"}>
                <h2>Partager un modèle d'acte</h2>
                {/*<p>Bienvenue sur votre profil ! Pour un matching efficace, merci de renseigner les champs ci-dessous. </p>*/}
            </div>

            <div id={"addmodelact_form"} className={"profil_block"}>
                <form>
                    <div className={"form_row_wrapper type2"}>
                        <label>Titre</label>
                        <input type={"text"} name={"title"} id={"title"} placeholder={"Titre"}/>
                    </div>
                    <div className={"form_row_wrapper type2"}>
                        <label>Description</label>
                        <textarea></textarea>
                    </div>
                    {/*<div className={"form_row_wrapper type2"}>*/}
                    {/*    <label>Spécialité</label>*/}
                    {/*    <input type={"text"} name={"speciality"} id={"speciality"} placeholder={"Type here"}/>*/}
                    {/*</div>*/}
                    {/*<div className={"form_row_wrapper type2"}>*/}
                    {/*    <label>Tags</label>*/}
                    {/*    <input type={"text"} name={"duration"} id={"duration"} placeholder={"Type here"}/>*/}
                    {/*</div>*/}
                    {/*<div className={"form_row_wrapper type2"}>*/}
                    {/*    <label>langue</label>*/}
                    {/*    <input type={"text"} name={"lang"} id={"lang"} placeholder={"Type here"}/>*/}
                    {/*</div>*/}
                    <div className={"form_row_wrapper dd_upload"}>
                        <FileUploader handleChange={(file) => {console.log(file)}} name="file" types={["PDF","DOCX"]} label={"Téléverser un modèle"}/>
                    </div>
                    <div className={"text-center mb2 mt3"}>
                        <button className={"cta full blue"}>Publier</button>
                    </div>
                    {/*<div className={"text-center"}>*/}
                    {/*    <button className={"cta small blue reverse"}>Enregistrer</button>*/}
                    {/*</div>*/}
                </form>
            </div>

        </div>
    )
}

export default AddModelAct