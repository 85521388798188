import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useOutletContext, useRevalidator} from "react-router-dom";

import SlidingPanel from "../_sliding_panel";
import {GenericEmptyItem} from "../../other/GenericBlock";
import {useSetState} from "react-use";
import {QuestionBlock} from "./_QuestionBlock";
import AddQuestion from "../../slidingContent/AddQuestion";

const Questions = () => {

    const {questions} = useLoaderData()
    const {user} = useOutletContext();
    const [showSlidingPanel, setShowSlidingPanel] = useState(false);
    const [questionsState, setQuestionsState] = useSetState()
    const revalidator = useRevalidator()

    useEffect(() => {
        questions.then((questions) => setQuestionsState({questions}))

    }, [questions]);

    return(
        <div id={"questions_wrapper"} className={"question_block_wrapper"}>
            <div className={"header"}>
                <div className={"text-center"}>
                    <button className={"cta full blue"} onClick={() => setShowSlidingPanel(true)}>Poser une question</button>
                </div>
            </div>

            <div className={"bloc_title_info"}>
                Discutez avec des confrères.<br/>
                Membre de la communaute #EsterLawyers
            </div>

            <div className={"switch_block_wrapper"}>

                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={questions}>
                        {(questions) => {
                            if (questions.length > 0) {
                                return (questions.map((question, key) => <QuestionBlock {...question} key={`question_${question.id}`} />))
                            } else {
                                return (<GenericEmptyItem title={"Aucune question pour le moment"}/>)
                            }
                        }}
                    </Await>
                </Suspense>

            </div>

            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)} forceSliding={true}>
                <AddQuestion handleClose={() => {
                    revalidator.revalidate()
                    setShowSlidingPanel(false)
                }}/>
            </SlidingPanel>
        </div>
    )
}

export default Questions