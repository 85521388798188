import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {emailPattern} from "../helper/formUtils";
import Api from "../helper/api";
import {CtaReturn} from "../other/Cta";

const ForgotPassword = () => {

    const {register,formState: { errors }, handleSubmit} = useForm();
    const [serverError, setServerError] = useState("");
    const api = new Api();
    const [confirmationEmail, setConfirmationEmail] = useState(null)

    const handleLostPassword = (data) => {

        setServerError("");

        api.userLostPassword(data.mail).then(res => {
            setConfirmationEmail(data.mail)
        }).catch(error => {
            setServerError(error.message)
            console.log(error)
        })
    }

    return(
        <div className={"connection_wrapper"}>
            <div className={"connection_box"}>
                <div className={"cta_wrapper"}>
                    <CtaReturn />
                </div>
                {confirmationEmail === null && <>
                    <h1>Mot de passe oublié</h1>
                    <p>Vous recevrez un email afin de récupérer votre mot de passe</p>
                    <form id={"connection_form"} onSubmit={handleSubmit(handleLostPassword)}>
                        <div className={"form_row_wrapper type3"}>
                            <label htmlFor={"mail"}>Email</label>
                            <input type={"text"} name={"mail"} id={"mail"} placeholder={"Adresse mail"} {...register("mail", {
                                required: "Veuillez renseigner votre adresse e-mail",
                                pattern: {
                                    value: emailPattern,
                                    message: "Veuillez saisir une adresse e-mail valide"
                                }
                            })}
                            />
                            <div className={"error"}>{errors.mail && errors.mail.message}</div>
                        </div>
                        <div className={"error text-center"}>{serverError}</div>

                        <div className={"form_row_wrapper type1"}>
                            <input type={"submit"} value={"Récupérer mon mot de passe"}/>
                        </div>
                    </form>
                </>}
                {confirmationEmail !== null && <>
                    <h1>Demande de récupération envoyée !</h1>
                    <p>Un email vient d'être envoyé à {confirmationEmail} contenant les instructions pour définir votre nouveau mot de passe.</p>
                    <div className={"form_row_wrapper type1"}>
                        <Link to={"/"} className={"cta blue full"}>Revenir à l'acccueil</Link>
                    </div>
                </>}
            </div>
        </div>
    )
}

export default ForgotPassword