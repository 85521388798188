export const GetHelpDate = (date) => {

    const options = { weekday:"long", year:"numeric", month:"long", day:"numeric"}
    const d = new Date(date)


    return d.toLocaleDateString('fr-FR', options)
}

export const GetHelpTime = (date, time) => {

    const options = { weekday:"long", year:"numeric", month:"long", day:"numeric"}
    const d = new Date(date)


    return d.getHours()+"h"+d.getMinutes();
}