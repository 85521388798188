import React, {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "./userAuth/AuthContext";
import {Link, useNavigate} from "react-router-dom";
import companyLogo from '../images/mobile/header_logo.png';
import {isMobile} from "react-device-detect";

const Header = (props) => {

    const {state: ContextState, logout} = useContext(AuthContext)
    const mobileMenuRef = useRef(null);

    const navigate = useNavigate()

    const toggleMobileMenu = () => {
        mobileMenuRef.current.classList.toggle("open");
    }

    return(
        <header>
            {ContextState.isLoggedIn && ContextState.user.updatedAt && <div id={"burger_menu"} onClick={toggleMobileMenu}/>}
            {(!ContextState.isLoggedIn || !ContextState.user.verified || (!ContextState.user.mission && !ContextState.user.skill)) && isMobile && <div className={"logo"} />}
            {ContextState.isLoggedIn && ContextState.user.verified && (ContextState.user.mission || ContextState.user.skill) &&
                <>
                    <div className={"logo"}>
                        <Link to={"/dashboard"}><img src={companyLogo} /></Link>
                    </div>
                    <ul className={"header-inline-menu"}>
                        <li>
                            <Link to={"/missions"}>Missions</Link>
                        </li>
                        <li>
                            <Link to={"/aides"}>Aide confraternelle</Link>
                        </li>
                        <li>
                            <Link to={"/echanges"}>Échanges de permanences</Link>
                        </li>
                        <li>
                            <Link to={"/communaute"}>Communauté</Link>
                        </li>
                        <li>
                            <Link to={"/messagerie"}>Messagerie</Link>
                        </li>
                    </ul>
                    <Link id={"notification"} to={"/notification"} className={ContextState.newNotifications ? 'notification-new' : ''}/>
                    <ul className={"header-inline-menu d-mobile-none"}>
                        <li>
                            <Link to={"/profile"}>Afficher mon profil</Link>
                        </li>
                    </ul>
                    {ContextState.user.updatedAt && ContextState.user.subscription && <div id={"profile"} onClick={() => navigate("/profile")}>
                        {props.user?.imageName && <img src={process.env.REACT_APP_API_URL+"/"+props.user.imageName}/> }
                    </div>}
                    {(!ContextState.user.updatedAt || !ContextState.user.subscription) && <Link to={"#0"} id={"logout"} onClick={logout}></Link>}

                </>
            }

            {ContextState.isLoggedIn && ContextState.user.verified && (ContextState.user.mission || ContextState.user.skill) && <div id={"mobile_menu_wrapper"} ref={mobileMenuRef}>
                <button id={"mobile_menu_close"} onClick={toggleMobileMenu}/>
                <nav>
                    <ul>
                        <li>
                            <Link to={"/dashboard"} onClick={toggleMobileMenu}>Dashboard</Link>
                        </li>
                        <li>
                            <Link to={"/missions"} onClick={toggleMobileMenu}>Missions</Link>
                        </li>
                        <li>
                            <Link to={"/aides"} onClick={toggleMobileMenu}>Aide confraternelle</Link>
                        </li>
                        <li>
                            <Link to={"/echanges"} onClick={toggleMobileMenu}>Échanges de permanences</Link>
                        </li>
                        <li>
                            <Link to={"/communaute"} onClick={toggleMobileMenu}>Communauté</Link>
                        </li>
                        {/*<li>*/}
                        {/*    <Link to={"/questions"} onClick={toggleMobileMenu}>Questions juridiques</Link>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <Link to={"/questions"} onClick={toggleMobileMenu}>Modèles d'acte</Link>*/}
                        {/*</li>*/}
                        <li>
                            <Link to={"/messagerie"} onClick={toggleMobileMenu}>Messagerie</Link>
                        </li>
                    </ul>
                </nav>
            </div>}

        </header>
    )
}

Header.defaultProps = {
    user: {}
}

export default Header