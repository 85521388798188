import Api from "../helper/api";

export const getSubscriptions = () => {
    const api = new Api()

    const subscriptionsListing = api.getSubscriptionsListing()
    // const subscription = api.getSubscriptionInfo()

    return({
        subscriptionsListing : subscriptionsListing
        // subscription : subscription
    })
}

export const getSubscription = () => {
    const api = new Api()

    const subscription = api.getSubscriptionInfo()
    return({
        subscription : subscription
    })
}