import React, {useContext, useState} from "react";
import * as Constant from "../../other/Constant";
import {Link} from "react-router-dom";
import {AuthContext} from "../../userAuth/AuthContext";
import FilterButtonBar from "../../other/FilterButtonBar";
import moment from "moment";

const ProfileHeader = ({user, onSelectTab, onChangeProfile, setShowEnableProfile}) => {
    // console.log(user, 'USER DETAIL')
    const {updateUserInGlobalContext, logout} = useContext(AuthContext)

    let filters = [
        {value: "dashboard", label: `Vue d'ensemble`},
        {value: "edit", label: `Mon profil`},
        {value: "sponsorship", label: `Parrainage`},
        {value: "membership", label: `Abonnement`},
        {value: "settings", label: `Paramètres`},
    ]
    if (!user.updatedAt) {
        filters = [
            {value: "edit", label: `Mon profil`},
        ]
    }

    return (
        <div className={"profile-header"}>
            <div className={"profile-header-top"} />
            <div className={"profile-header-picture"}>
                {user?.imageName && <img src={process.env.REACT_APP_API_URL+"/"+user.imageName}/> }
            </div>
            <div className={"profile-header-content"}>
                <div className={"profile-header-detail"}>
                    <div className={"profile-header-name"}>
                        {user.firstname} {user.lastname}
                        {user.job === 0 && <p className={"user_profile_type"}>{user.profileType === Constant.PROFIL_TYPE_RECRUTEUR ? 'Je publie des missions' : 'Je propose mes compétences'}</p>}
                    </div>
                    <div className={"profile-header-skills"}>
                        <ul>
                            {user.skills?.map(skill => <li>{skill.area?.name}</li>)}
                        </ul>
                        <div>Actif depuis le {moment(user.createdAt ? user.createdAt : user.updatedAt).format('DD/MM/YYYY')}</div>
                    </div>
                    <div className={"profile-header-info"}>
                        {user.location && <div className={"location"}>{user.location}</div>}
                        {user.bar && <div className={"bar"}>{user.bar?.name}</div>}
                        {user.experience && <div className={"experience"}>{user.experience?.name}</div>}
                    </div>
                </div>
                {user.job === 0 && user.mission == 1 && user.profileType === Constant.PROFIL_TYPE_CANDIDAT &&
                    <Link to={"#0"} className={"cta profile_switch full"} onClick={() => onChangeProfile(Constant.PROFIL_TYPE_RECRUTEUR)}>Aller vers le profil "Je propose des missions"</Link>
                }
                {user.job === 0 && user.skill == 1 && user.profileType === Constant.PROFIL_TYPE_RECRUTEUR &&
                    <Link to={"#0"} className={"cta profile_switch full"} onClick={() => onChangeProfile(Constant.PROFIL_TYPE_CANDIDAT)}>Aller vers le profil "Je propose mes compétences"</Link>
                }
                {user.job === 0 && user.mission == 0 && user.profileType === Constant.PROFIL_TYPE_CANDIDAT &&
                    <Link to={"#0"} className={"cta profile_switch full"} onClick={() => setShowEnableProfile(true)}>Activer le profil recruteur</Link>
                }
                {user.job === 0 && user.skill == 0 && user.profileType === Constant.PROFIL_TYPE_RECRUTEUR &&
                    <Link to={"#0"} className={"cta profile_switch full"} onClick={() => setShowEnableProfile(true)}>Activer le profil talent</Link>
                }
            </div>

            <div className={"profile-header-bottom"}>
                <div className={"profile-header-tabs"}>
                    <FilterButtonBar filters={filters} setFilterValue={onSelectTab}/>
                </div>
                <Link to={"#0"} className={"profile-header-logout"} onClick={logout}>Se déconnecter</Link>
            </div>
        </div>
    )
}

export default ProfileHeader;