import React from "react";
import { useNavigate, useOutletContext} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Api from "../helper/api";

const CommunityRoute = (props) => {

    const { user } = useOutletContext()
    const navigate = useNavigate()
    const handleRejectCommunityPolicy = () => {
        navigate(-1)
    }

    const handleAcceptCommunityPolicy = () => {
        const api = new Api()
        api.UpdateUser(user.id,{communityPolicy : true}).then(() => navigate(0))
    }

    if (!props.readOnly && user?.communityPolicy) {
        return(<ProtectedRoute>{props.children}</ProtectedRoute>)
    }

    return(
        <div id={"community_cgu_wrapper"}>
            <h1>Charte d’utilisation de la Communauté #esterLawyers</h1>
            <p></p>
            <p>
                Que vous soyez avocat confirmé ou jeune avocat, cet espace est fait pour vous.<br/><br/>
                Bienvenue dans la communauté #esterLawyers composée uniquement d’avocats super confraternels inscrits dans un barreau français.<br/><br/>
                Nous avons créé cet espace avec beaucoup de 💟<br/><br/>
                Notre but est de vous proposer des services qui facilitent l’exercice de votre activité professionnelle et les relations confraternelles.<br/><br/>
                L’espace #esterLawyers est une plateforme communautaire éditée par EsterLaw SAS exclusivement réservée aux avocats utilisateurs d’EsterLaw ayant un compte EsterLaw actif et qui souhaitent rejoindre à la communauté #esterLawyers.<br/><br/>
                L’accès à cet espace est subordonné à l’acceptation des  Conditions Générales de Service et de ventes (CGS/CGV) d’EsterLaw et de la présente charte d’utilisation de la communauté #esterLawyers<br/><br/>
                La Charte d’utilisation a pour objet de régir les règles d’accès et d’utilisation des services proposés sur la plateforme de la communauté #esterLawyers. La Charte a une valeur contractuelle entre Esterlaw SAS et vous, en l’acceptant vous vous engagez à la respecter.<br/><br/>
                La Charte vous explique ce que vous pourrez trouver et attendre de la communauté #esterLawyers et comment vous pouvez y participer.<br/><br/>
                Les informations personnelles renseignées dans votre compte utilisateur EsterLaw sont utilisées pour votre participation à la communauté.<br/><br/>
                Vous vous engagez à tenir à jour ces renseignements et à quitter la communauté en cas de suspension ou de radiation du tableau de votre ordre.<br/>
            </p>
            <h2>Que pouvez-vous y trouver et y faire ?</h2>
            <p>
                Les services de la communauté sont exclusivement dédiés aux questions et sujets liés à l’exercice de votre profession d’avocat, vous pourrez :<br/>
                <ul>
                    <li>Trouver de l’aide gratuite entre confrères ;</li>
                    <li>Échanger des permanences ;</li>
                    <li>Poser à la communauté des questions juridiques et questions pratiques en lien avec l’exercice de la profession d’avocat ;</li>
                    <li>Discuter entre avocats membres ;</li>
                    <li>Publier et trouver des modèles d’actes proposés par les membres de la communauté ;</li>
                    <li>Réagir en cliquant sur le « cœur » et /ou commenter;</li>
                    <li>Trouver des offres réservées aux membres de la communauté par divers partenaires d’EsterLaw;</li>
                    <li>Participer à des évènements qui pourront être proposés aux Membres ;</li>
                    <li>Le fil d’actualité : Les membres trouveront les dernières publications et contenus</li>
                </ul>
                L'aide confraternelle s’entend de tous services rendus gratuitement. Il est interdit de poster dans la communauté une demande d’aide contre paiement d’honoraires.<br/>
                Ce type de demande doit exclusivement être posté sur la plateforme EsterLaw.<br/><br/>
            </p>
            <h2>Qui peut accéder aux contenus publiés sur la plateforme ?</h2>
            <p>
                La communauté #esterLawyers est privée, seuls les avocats utilisateurs d’EsterLaw ayant souhaité accéder à la communauté ont accès à la plateforme #esterLawyers.<br/><br/>
                Il n’est pas possible de partager les contenus sur les réseaux sociaux.<br/><br/>
            </p>
            <h2>Comment fonctionnent les demandes d’aide et d’échanges de permanence ?</h2>
            <p>
                Il vous suffit de cliquer sur l’action souhaitée et de remplir les champs proposés.<br/>
                Lorsque vous demandez un petit service à titre confraternel ou proposez un échange de permanence, notre algorithme diffuse anonymement votre demande aux confrères de votre Barreau ou proche du lieu concerné (juridiction/commissariat etc.).<br/><br/>
                Lorsqu’un de vos confrères propose son aide ou est intéressé par un échange de permanence, Ester Law communique les identités et vous met en relation au moyen d’une messagerie instantanée et d’une solution de visioconférence intégrées à l’application.<br/><br/>
                Les messages échangés à partir de ce service sont des correspondances privées et ne sont pas accessibles par EsterLaw. Les messages relèvent de la pleine et entière responsabilité des membres. Toutefois tout membre peut signaler un message qu’il estime contraire à la présente charte d’utilisation et/ou aux CGS/CGV d’EsterLaw.<br/><br/>
            </p>
            <h2>Qui est responsable des contenus ?</h2>
            <p>
                Les avocats membres de la communauté #esterLawyers sont seuls et entièrement responsables des contenus qu’ils publient sur la plateforme : forums, questions, réponses, avis, commentaires, modèles d’actes etc.<br/><br/>
                Il s’agit d’une communauté et forum privé sur lequel EsterLaw SAS n’effectue aucune modération a priori.<br/><br/>
                EsterLaw SAS n’exerce qu’un rôle d’intermédiaire technique entre les utilisateurs professionnels. Le service proposé par EsterLaw est uniquement de mettre le site et la technologie développée au service de ses utilisateurs et membres.<br/><br/>
                Il appartient aux avocats de veiller scrupuleusement au respect des règles déontologiques de leurs professions dont le secret professionnel, le respect des limites de la liberté d’expression, le respect des droits des tiers dont les droits d’auteurs.<br/><br/>
                Il leur appartient de vérifier qu’ils disposent des droits nécessaires notamment dans le cadre des modèles d’actes partagés avec la communauté.<br/><br/>
                A ce titre vous déclarez expressément être titulaire des droits de diffusion et d’exploitation des contenus que vous diffusez et vous concédez sans aucune limite ni restriction à EsterLaw SAS ainsi qu’aux avocats membres de la communauté les droits d’exploitation des modèles d’actes diffusés qui pourront être librement et gratuitement téléchargés.<br/><br/>
                Les modèles d’actes sont fournis à titre indicatifs, gratuits et confraternels par les avocats membres de la communauté #esterLawyers, il appartient aux avocats membres qui téléchargent les modèles pour les utiliser de les valider, de les adapter en fonction des situations, des dispositions légales et de leurs pratiques professionnelles.<br/><br/>
                En tout état de cause, EsterLaw SAS n’intervient en aucune manière et en sa qualité de prestataire technique elle n’effectue aucune vérification des contenus et modèles diffusés.<br/><br/>
                Vous reconnaissez expressément décharger EsterLaw SAS de toute responsabilité au titre de l’intégralité des contenus diffusés sur la plateforme #esterLawyers et utilisés sous la seule responsabilité des avocats membres de la communauté #esterLawyers.<br/><br/>
                Les membres #esterLawyers s’engagent les uns envers les autres à avoir une utilisation éthique et confraternelle des informations et actes qu’ils diffusent et téléchargent.<br/><br/>
                Tout différend qui pourrait naître à ce sujet sera réglé directement entre les membres selon les règles de leur profession, vous déchargez expressément Esterlaw SAS de toute responsabilité à quelque titre et de quelque nature que ce soit.<br/><br/>
                Vous vous engagez en tant que Membre de la communauté #esterLawyers à respecter l’ensemble des dispositions des CGS/ CGV dont notamment celles stipulées à l’article 6 des CGS/CGV ainsi que la présente charte.<br/><br/>
                Par ailleurs, vous reconnaissez et acceptez que vous demeurez seul responsable des traitements que vous réalisez sur la plateforme, vous vous engagez à respecter les dispositions relatives aux règles professionnelles, aux lois et règlements relatifs à la protection des données et assumez seul la responsabilité de toute collecte ou communication de données inappropriée et/ ou contraire aux règles relatives à la confidentialité et/ou le secret professionnel.<br/><br/>
                Vous reconnaissez que vous assumerez seul toutes les conséquences juridiques et judiciaires directes ou indirectes de tout comportement ou traitement inapproprié ou illégal.<br/><br/>
                Vous pouvez nous signaler tout contenu inapproprié ou illégal à l’aide du formulaire que vous trouverez dans votre compte utilisateur et dans votre compte Membre.<br/><br/>
            </p>

            <h2>Comment supprimer votre compte membre #esterLawyer ?</h2>
            <p>
                Vous pouvez supprimer votre compte depuis votre compte Membre #esterLawyers  en cliquant sur  « quitter la communauté ».<br/><br/>
                L’action n'entraînera pas la suppression de votre compte Utilisateur/client EsterLaw.<br/><br/>
                Votre attention est attirée sur le fait cette action n'entraînera pas la suppression des publications postées précédemment qui resteront visibles.<br/><br/>
                Vous pouvez manuellement supprimer l’ensemble de vos publications dans la communauté.<br/><br/>
                Attention, si vous souhaitez supprimer vos publications, pensez à la supprimer manuellement avant de cliquer sur « Quitter la communauté ».<br/><br/>
                Les échanges privés avec les autres membres resteront visibles par eux (demandes d’aides, échanges de permanences etc.).<br/><br/>
                Version en vigueur au 03.04.2024
            </p>
            <br/>
            <br/>
            <div className={"wta_wrapper"}>
                {!props.readOnly && <a className={"cta full blue"} onClick={handleAcceptCommunityPolicy}>Accepter</a>}
                {!props.readOnly && <a className={"cta full reverse blue"} onClick={handleRejectCommunityPolicy}>Refuser</a>}
                {props.readOnly && <a className={"cta full blue"} onClick={props.handleClose}>Fermer</a>}
            </div>
        </div>
    )
}

export default CommunityRoute