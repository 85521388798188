import React, {useContext, useEffect, useState} from "react";
import {useSetState} from "react-use";
import {useForm} from "react-hook-form";
import {Link, useLocation, useNavigate} from "react-router-dom";

import Api from "../helper/api";
import {AuthContext} from "../userAuth/AuthContext";

import PasswordStrengthBar from 'react-password-strength-bar';
import {emailPattern} from "../helper/formUtils";
import PhoneInput from "react-phone-input-2";
import {CtaReturn} from "../other/Cta";

const SignupSSO = () => {

    const [serverError, setServerError] = useState("")

    //user context
    const {login} = useContext(AuthContext);

    //API
    const api = new Api();
    const [formDataState, setFormDataState] = useSetState({
        jobList : []
    })

    //SSO data
    const location = useLocation()
    const navigate = useNavigate()

    //Load form data and check SSO param
    useEffect(() => {
        const fetchData = async () => {
            const jobs = await api.getJob()

            setFormDataState({
                jobList : jobs
            })
        }

        //if SSO data are not found
        if(!location.state.email){
            navigate("/connexion")
            return;
        }

        fetchData()
    }, [])

    //handle form
    const { register, formState: { errors }, handleSubmit,setValue } = useForm();

    const handleFormSubmit = (data) => {

        //profilType
        // 0 -> proposer mission
        // 1 -> proposer compétence

        //Default value
        const user = {
            "email" : location.state.email,
            "password" : location.state.password,
            "job": parseInt(data.job),
            "profileType": 1,
            "lastname" : data.lastname,
            "firstname": data.firstname,
            "phone": data.phone,
            "googleId": location.state.googleId,
            "locale": location.state.locale,
            "mission" : false,
            "skill" : false,
        }

        //user is not a lawer
        if (data.job !== "0"){
            user.skill = true
            user.profileType = 0
        }


        createUserAccount(user)
    }

    /**
     * Finalize sign up journey
     */
    const createUserAccount = async (user) => {

        setServerError("");

        await api.createUser(user).then((res) => {

            //connect user and redirect to dashboard
            login(user.email, user.password).catch((error) => {
                setServerError(error.response.data.message)
            });

        }).catch(error => {
            setServerError(error.message);
        })

    }

    return(
        <div className={"connection_wrapper"}>
            <div className={"connection_box"}>
                <div className={"cta_wrapper"}>
                    <CtaReturn />
                </div>
                <h1>S'inscrire</h1>
                <form id={"type_registration_form"} onSubmit={handleSubmit(handleFormSubmit)}>

                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"job"}>Je suis</label>
                        <select name="job" defaultValue={""} {...register("job", {required: "Veuillez renseigner cette information"})}>
                            <option value={""}>Je suis</option>
                            {formDataState.jobList.map((item) => {
                                return (<option value={item.id}>{item.name}</option>)
                            })}
                        </select>
                        <div className={"error"}>{errors.job && errors.job.message}</div>
                    </div>

                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"firstname"}>Prénom</label>
                        <input type={"text"} name={"firstname"} id={"firstname"} placeholder={"Prénom"} {...register("firstname", {required: "Veuillez renseigner votre prénom"})}/>
                        <div className={"error"}>{errors.firstname && errors.firstname.message}</div>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"firstname"}>Nom</label>
                        <input type={"text"} name={"lastname"} id={"lastname"} placeholder={"Nom"} {...register("lastname", {required: "Veuillez renseigner votre nom"})}/>
                        <div className={"error"}>{errors.lastname && errors.lastname.message}</div>
                    </div>

                    <div className={"form_row_wrapper type3"}>
                        <input type={"hidden"} name={"phone"} id={"phone"} {...register("phone", {required: "Veuillez renseigner votre numéro de téléphone"})}/>

                        <PhoneInput
                            country={'fr'}
                            onChange={phone => setValue("phone", "+" + phone)}
                            placeholder={"602030405"}
                        />
                        <label htmlFor={"phone"}>Téléphone</label>
                        <div className={"error"}>{errors.phone && errors.phone.message}</div>
                    </div>

                    <div className={"error"}>
                        {serverError}
                    </div>

                    <div className={"form_row_wrapper type1"}>
                        <input type={"submit"} value={"S’inscrire gratuitement"}/>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SignupSSO