// VideoCall.js
import React, { useEffect, useRef, useState } from 'react';
import Video from 'twilio-video';
import {Link, useNavigate, useParams} from "react-router-dom";
import Api from "../../helper/api";

const VideoChat = () => {

    const [room, setRoom] = useState(null);
    const localVideoRef = useRef();
    const remoteVideoRef = useRef();
    const navigate = useNavigate()
    const [mute, setMute] = useState(false)
    const api = new Api();
    const {conversationId} = useParams()
    const [isConnected, setIsConnected] = useState(false);

    const onEndCall = () => {
        if (room) {
            room.disconnect();
        }
        navigate(-1)
    }

    const onMute = () => {
        if (room) {
            const localParticipant = room.localParticipant;
            const audioTrack = localParticipant.audioTracks.values().next().value;

            if (audioTrack) {
                if (mute) {
                    audioTrack.track.enable();
                } else {
                    audioTrack.track.disable();
                }
                setMute(!mute)
            }
        }
    }


    const initVideoRoom = async () => {
        const data = await api.getVisioToken(conversationId)

        if (data?.token) {
            Video.connect(data.token, { video: true, audio: true}).then((room) => {
                setRoom(room);
                setIsConnected(true);
                room.localParticipant.videoTracks.forEach(trackPub => {
                    if (trackPub.track) {
                        console.log("TRACKL OCAL OK")
                        localVideoRef.current.appendChild(trackPub.track.attach());
                    } else {
                        console.log("FUUUUUCK")
                    }
                });

                room.localParticipant.on('trackPublished', (trackPub) => {
                    console.log('Piste vidéo publiée par le participant local :', trackPub.track);
                    // Ici, tu peux vérifier si la piste vidéo est bien souscrite
                    if (trackPub.track.isSubscribed) {
                        console.log('La piste est souscrite et envoyée');
                    } else {
                        console.log('La piste n\'est pas encore souscrite');
                    }
                })

                room.localParticipant.on('trackSubscribed', (track) => {
                    console.log("La piste vidéo locale a été souscrite :", track);
                });

                room.on('participantConnected', participant => {
                    // console.log(participant, 'NEW PARTICIPANT')
                    participant.videoTracks.forEach(trackPub => {
                        console.log(trackPub.isSubscribed, 'NEW TRACK PUB 222')
                        if (trackPub.track) {
                            if (trackPub.isSubscribed) {
                                console.log('Piste déjà souscrite');
                                remoteVideoRef.current.appendChild(trackPub.track.attach());
                            } else {
                                console.log('Piste non souscrite, en attente de l\'événement "subscribed"');

                                // Attends que la piste soit souscrite
                                trackPub.track.on('subscribed', track => {
                                    remoteVideoRef.current.appendChild(track.attach());
                                });

                                // En cas d'erreur lors de l'abonnement
                                trackPub.track.on('failed', (error) => {
                                    console.error('Échec de l\'abonnement à la piste vidéo:', error);
                                });
                            }
                        }
                        // if (trackPub.isSubscribed && trackPub.track) {
                        //     //     console.log(trackPub.track, 'NEW TRACK PUB')
                        //     trackPub.track.on('subscribed', track => {
                        //         console.log(track, 'NEW TRACK')
                        //         if (track) {
                        //             remoteVideoRef.current.appendChild(track.attach());
                        //         }
                        //     });
                        // } else {
                        //     console.log('PAS DE TRACK PUB')
                        // }
                    });
                });

                room.on('participantDisconnected', participant => {
                    participant.videoTracks.forEach(trackPub => {
                        if (trackPub.track) {
                            trackPub.track.detach().forEach(element => element.remove());
                        }
                    });
                });

                room.on('disconnected', () => {
                    room.localParticipant.videoTracks.forEach(trackPub => {
                        if (trackPub.track) {
                            trackPub.track.detach().forEach(element => element.remove());
                        }
                    });
                    setRoom(null);
                    setIsConnected(false);
                });
            }).catch(error => {
                console.error('Erreur de connexion à la room:', error);
            });
        }
    }

    useEffect(() => {
        if (isConnected) return;

        initVideoRoom()

        // return () => {
        //     room && room.disconnect();
        // };
    }, [conversationId, isConnected]);

    return (
        <div className={"video_wrapper"}>
            <div ref={remoteVideoRef}></div>
            <div ref={localVideoRef}></div>
            {room && <div className={"video_actions"}>
                <Link href={'#0'} className={`mute ${!mute ? 'unmute' : ''}`} onClick={() => { onMute(); }} />
                <Link href={'#0'} className={"endCall"} onClick={() => { onEndCall(); }} />
            </div>}
        </div>
    );
};

export default VideoChat;
