import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useLocation, useNavigate} from "react-router-dom";

import {GenericEmptyItem} from "../../other/GenericBlock";
import {CtaReturn} from "../../other/Cta";
import SingleMatch from "../match/SingleMatch";
import Api from "../../helper/api";
import {isMobile} from 'react-device-detect';

const MatchBlock = ({title, compatibility, itemId, type, isLiked, onClick}) => {

    const navigate = useNavigate()

    return(
        <div className={`match_item list ${isLiked?"liked":""}`} onClick={() => onClick()}>
            {/*<div className={`match_item list ${isLiked?"liked":""}`} onClick={() => navigate("/matchs/"+type+"/"+itemId)}>*/}
            <div className={"content"}>
                <div className={"title"}>{title}</div>
                <div className={"tags"}>
                    <span>{compatibility}% compatible</span>
                </div>
            </div>
        </div>
    )
}

const SingleItemMatchsListing = () => {
    const location = useLocation();
    // console.log(location, 'CHECK PARAMS')

    const {matchs, type} = useLoaderData()
    const [matchsList, setMatchsList] = useState([]);
    const navigate = useNavigate()
    const [match, setMatch] = useState(null)
    const api = new Api()

    useEffect(() => {
        matchs.then((matchs) => {

            let formatted_matchs = []
            matchs.forEach((match, key) => {
                let title = `Match ${key + 1}`
                if (match.user && match.user?.lastname && match.user?.firstname){
                    title = `${match.user?.firstname} ${match.user?.lastname}`
                }

                formatted_matchs.push(<MatchBlock title={title} compatibility={50} itemId={match.id} type={type} isLiked={match.bookmarks?.length > 0} onClick={() => {
                    if (isMobile) {
                        navigate("/matchs/"+type+"/"+match.id)
                    } else {
                        const matchPromise = api.getMatch(match.id)
                        setMatch(matchPromise)
                    }
                }}/>)
            })
            if (!isMobile) {
                setMatch(matchs[0])
            }
            setMatchsList(formatted_matchs)
        })
    },[])

    return(
        <div className={"single_item_match_listing_wrapper"}>
            <div className={"cta_wrapper"}>
                <CtaReturn/>
            </div>
            <h2 className={"title"}>{location.state?.title}</h2>
            <div className={"generic_data_wrapper"}>

                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={matchs}>
                        {matchsList.length > 0 && <>
                            <div className={"side-right"}>
                                {matchsList}
                            </div>
                            {!isMobile && <div className={"side-left border-side-left"}>
                                {match !== null && <SingleMatch match={match}/>}
                            </div>}
                        </>}
                        {matchsList.length === 0 && <GenericEmptyItem title={"Aucun match pour le moment"}/>}
                    </Await>
                </Suspense>
            </div>

        </div>
    )
}

export default SingleItemMatchsListing