import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useOutletContext, useRevalidator} from "react-router-dom";

import FilterButtonBar from "../../other/FilterButtonBar";

import SlidingPanel from "../_sliding_panel";
import {GenericEmptyItem} from "../../other/GenericBlock";
import {useSetState} from "react-use";
import AddHelpRequest from "../../slidingContent/help/AddHelpRequest";
import HelpBlock, {HelpFullBlock} from "./_HelpBlock";
import AddNoteToHelp from "../../slidingContent/help/addNoteToHelp";
import ConfirmHelpRequest from "../../slidingContent/help/confirmHelpRequest";

const Helps = () => {

    const [filterValue, setFilterValue] = useState(0);
    const {helps, urgentHelps, userHelps, userApplications, allDones} = useLoaderData()
    const {user} = useOutletContext();
    const [showSlidingPanel, setShowSlidingPanel] = useState(false);
    const [helpsState, setHelpsState] = useSetState()
    const revalidator = useRevalidator()
    const [showSlidingPanelAddNote, setShowSlidingPanelAddNote] = useState(false);
    const [showSlidingPanelConfirmHelp, setShowSlidingPanelConfirmHelp] = useState(false);
    const [currentHelpId, setCurrentHelpId] = useState(false);

    useEffect(() => {
        helps.then((helps) => setHelpsState({helps}))
        urgentHelps.then((urgent_helps) => setHelpsState({urgent_helps}))
        userHelps.then((user_helps) => setHelpsState({user_helps}))
        userApplications.then((user_applications) => setHelpsState({user_applications}))

    }, [helps, urgentHelps, userHelps, userApplications]);

    /**
     * Set help Id and show AddNote Form
     * @param helpId
     */
    const handleAddNoteToHelp = (helpId) => {
        setCurrentHelpId(helpId)
        setShowSlidingPanelAddNote(true)
    }

    /**
     * Set help Id and show confirm form
     * @param helpId
     */
    const handleConfirmHelp = (helpId) => {
        setCurrentHelpId(helpId)
        setShowSlidingPanelConfirmHelp(true)
    }

    return(
        <div id={"helps_wrapper"}>
            <div className={"header"}>
                <FilterButtonBar filters={[
                    {value: "help", label: `Demandes des confrères`, count: helpsState.helps?.length},
                    {value: "user_help", label: `Mes demandes`, count: helpsState.user_helps?.length},
                    {value: "user_application", label: `J'aide`, count: helpsState.user_applications?.length},
                    {value: "user_history", label: `Historique`}
                ]} setFilterValue={setFilterValue}/>

                <div className={"text-center"}>
                    <button className={"cta full blue"} onClick={() => setShowSlidingPanel(true)}>Demander de l'aide</button>
                </div>
            </div>

            {filterValue === 0 &&
                <div id={"help_current"} className={"filter_container"}>

                    <div className={"bloc_title_info"}>
                        <p>Retrouvez ici toutes les demandes d'aide confraternelle de la communauté autour de vous.</p>
                    </div>

                    <div className={"help_block_wrapper generic_data_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={helps}>
                                {(helps) => {
                                    if (helps.length > 0) {
                                        return (helps.map((help, key) => <HelpFullBlock {...help} key={`help_${help.id}`} handleAddNote={handleAddNoteToHelp} handleConfirm={handleConfirmHelp} showActions={true} />))
                                    } else {
                                        return (<GenericEmptyItem title={"Aucune demande pour le moment"}/>)
                                    }
                                }}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }

            {filterValue === 1 &&
                <div id={"help_user_help"} className={"filter_container"}>
                    <div className={"bloc_title_info"}>
                        <p>Retrouvez ici vos demandes et les propositions d'aides de vos confrères en détail.</p>
                    </div>
                    <div className={"help_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={userHelps}>
                                {(helps) => {
                                    if (helps.length > 0) {
                                        return (helps.map((help, key) => <HelpFullBlock {...help} key={`help_${help.id}`} showActions={true} />))
                                    } else {
                                        return (<GenericEmptyItem title={"Aucune demande pour le moment"}/>)
                                    }
                                }}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }

            {filterValue === 2 &&
                <div id={"help_application"} className={"filter_container"}>
                    <div className={"bloc_title_info"}>
                        <p>Retrouvez ici les aides pour lesquelles vous avez proposé votre aide.</p>
                    </div>
                    <div className={"help_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={userApplications}>
                                {(applications) => {
                                    if (applications.length > 0) {
                                        return (applications.map((application, key) => <HelpFullBlock {...application.help} key={`help_${application.help.id}`} showActions={false} />))
                                    } else {
                                        return (<GenericEmptyItem title={"Aucune candidature pour le moment"}/>)
                                    }
                                }}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }

            {filterValue === 3 &&
                <div id={"help_application"} className={"filter_container"}>
                    <div className={"bloc_title_info"}>
                        <p>Retrouvez ici les aides terminées.</p>
                    </div>
                    <div className={"help_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={allDones}>
                                {(dones) => {
                                    if (dones.length > 0) {
                                        return (dones.map((item, key) => <HelpFullBlock {...item.help} key={`help_${item.help.id}`} showActions={false} />))
                                    } else {
                                        return (<GenericEmptyItem title={"Aucune aide terminée pour le moment"}/>)
                                    }
                                }}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }

            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)} forceSliding={true}>
                <AddHelpRequest user={user} handleClose={() => {
                    revalidator.revalidate()
                    setShowSlidingPanel(false)
                }}/>
            </SlidingPanel>

            <SlidingPanel show={showSlidingPanelAddNote} handleClose={() => setShowSlidingPanelAddNote(false)}>
                <AddNoteToHelp handleClose={() => setShowSlidingPanelAddNote(false)} helpId={currentHelpId}/>
            </SlidingPanel>

            <SlidingPanel show={showSlidingPanelConfirmHelp} handleClose={() => setShowSlidingPanelConfirmHelp(false)}>
                <ConfirmHelpRequest handleClose={() => setShowSlidingPanelConfirmHelp(false)} helpId={currentHelpId}/>
            </SlidingPanel>
        </div>
    )
}

export default Helps