import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../userAuth/AuthContext";
import {GetHelpDate, GetHelpTime} from "../../helper/other";

export const ActBlock = (props) => {

    const {state} = useContext(AuthContext)
    const navigate = useNavigate()

    return (
        <div className={"dashboard_block document_block"}>
            <div className={"title"}>{props.title}</div>
            {/*<div className={"date"}><span>@JohnDoe</span>, 5 hours ago</div>*/}
            <div className={"description"}>{props.description}</div>
            {/*<div className={"stat"}>*/}
            {/*    <div>59</div>*/}
                {/*<div>*/}
                {/*    <span>Droit des entreprises</span>*/}
                {/*    <span className={"more"}>+1</span>*/}
                {/*</div>*/}
            {/*</div>*/}
        </div>
    )

}

ActBlock.defaultProps = {
    title: "-",
    description: "-",
    createdOn: "1970-01-01 00:00:00",
    likes: [],
    filename: ""
}

export default ActBlock