import React from "react";

/**
 * Used to display user skill / jobs / etcs ....
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ProfilDataBlock = (props) => {

    const {title, experience, international, description, subareas, editCallBack, deleteCallBack} = props

    return(
        <div className={"profilDataBlock"}>
            <div className={"cta_wrapper"}>
                {editCallBack != null && <button className={"cta edit"} onClick={(e) => {
                    e.preventDefault()
                    editCallBack()
                }}>edit</button> }
                {deleteCallBack != null && <button className={"cta delete"} onClick={(e) => {
                    e.preventDefault()
                    deleteCallBack()
                }}>delete</button> }
            </div>
            {title && <div className={"title"}>{title}</div> }
            {experience && <div className={"experience"}>Expérience : {experience}</div> }
            {international.length !== 0 &&
                <div className={"international"}>
                    Compétence internationale :&nbsp;
                    {international.map((c,k) => {
                        return(
                            k !== 0 ? " / "+c.name : c.name
                        )
                    })}
                </div>
            }
            {description && <div className={"description"}>{description}</div>}
            {subareas &&
                <div className={"subarea"}>
                    {subareas.map((sa,k) => {
                        return(<span>{sa.name}</span>)
                    })}
                </div>
            }
        </div>
    )
}

ProfilDataBlock.defaultProps = {
    title : "",
    experience : "",
    international : [],
    description : "",
    subareas : [],
    editCallBack : null,
    deleteCallBack : null
}

export default ProfilDataBlock