import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useOutletContext, useRevalidator} from "react-router-dom";

import FilterButtonBar from "../../other/FilterButtonBar";

import SlidingPanel from "../_sliding_panel";
import {GenericEmptyItem} from "../../other/GenericBlock";
import {useSetState} from "react-use";
import {SwitchFullBlock} from "./_SwitchBlock";
import AddPermanenceRequest from "../../slidingContent/switch/AddPermanenceRequest";
import AddHelpRequest from "../../slidingContent/help/AddHelpRequest";
import AddNoteToHelp from "../../slidingContent/help/addNoteToHelp";
import ConfirmHelpRequest from "../../slidingContent/help/confirmHelpRequest";
import AddNoteToSwitch from "../../slidingContent/switch/addNoteToSwitch";
import ConfirmSwitchRequest from "../../slidingContent/switch/confirmSwitchRequest";

const Switchs = () => {

    const [filterValue, setFilterValue] = useState(0);
    const {switchs, urgentSwitchs, userSwitchs, userApplications, allDones} = useLoaderData()
    const {user} = useOutletContext();
    const [showSlidingPanel, setShowSlidingPanel] = useState(false);
    const [switchsState, setSwitchState] = useSetState()
    const revalidator = useRevalidator()
    const [showSlidingPanelAddNote, setShowSlidingPanelAddNote] = useState(false);
    const [showSlidingPanelConfirmSwitch, setShowSlidingPanelConfirmSwitch] = useState(false);
    const [currentSwitchId, setCurrentSwitchId] = useState(false);

    useEffect(() => {
        switchs.then((switchs) => setSwitchState({switchs}))
        urgentSwitchs.then((urgent_switchs) => setSwitchState({urgent_switchs}))
        userSwitchs.then((user_switchs) => setSwitchState({user_switchs}))
        userApplications.then((user_applications) => setSwitchState({user_applications}))

    }, [switchs, urgentSwitchs, userSwitchs, userApplications]);

    //sliding panel

    /**
     * Set switch Id and show AddNote Form
     * @param switchId
     */
    const handleAddNoteToSwitch = (switchId) => {
        setCurrentSwitchId(switchId)
        setShowSlidingPanelAddNote(true)
    }

    /**
     * Set switch Id and show confirm form
     * @param switchId
     */
    const handleConfirmSwitch = (switchId) => {
        setCurrentSwitchId(switchId)
        setShowSlidingPanelConfirmSwitch(true)
    }

    return(
        <div id={"switchs_wrapper"}>
            <div className={"header"}>

                <FilterButtonBar filters={[
                    {value: "switch", label: `Demandes des confrères`, count: switchsState.switchs?.length},
                    {value: "user_switch", label: `Mes demandes`, count: switchsState.user_switchs?.length},
                    {value: "user_application", label: `J'aide`, count: switchsState.user_applications?.length},
                    {value: "user_history", label: `Historique`}
                ]} setFilterValue={setFilterValue}/>

                <div className={"text-center"}>
                    <button className={"cta full blue"} onClick={() => setShowSlidingPanel(true)}>Changer une permanence</button>
                </div>
            </div>

            {filterValue === 0 &&
                <div id={"switch_current"} className={"filter_container"}>

                    <div className={"bloc_title_info"}>
                        <p>Retrouvez ici toutes les demandes d'échange de permanence de la communauté autour de vous.</p>
                    </div>

                    <div className={"switch_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={switchs}>
                                {(switchs) => {
                                    if (switchs.length > 0) {
                                        return (switchs.map((s, key) => <SwitchFullBlock {...s} key={`switch_${s.id}`} handleAddNote={handleAddNoteToSwitch} handleConfirm={handleConfirmSwitch} showActions={true} />))
                                    } else {
                                        return (<GenericEmptyItem title={"Aucune demande pour le moment"}/>)
                                    }
                                }}
                            </Await>
                        </Suspense>

                    </div>
                </div>
            }

            {filterValue === 1 &&
                <div id={"switch_user_switch"} className={"filter_container"}>
                    <div className={"bloc_title_info"}>
                        <p>Retrouvez ici vos demandes et les propositions d'échanges de vos confrères en détail.</p>
                    </div>

                    <div className={"switch_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={userSwitchs}>
                                {(switchs) => {
                                    if (switchs.length > 0) {
                                        return (switchs.map((s, key) => <SwitchFullBlock {...s} key={`switch_${s.id}`} showActions={true} />))
                                    } else {
                                        return (<GenericEmptyItem title={"Aucune demande pour le moment"}/>)
                                    }
                                }}
                            </Await>
                        </Suspense>

                    </div>
                </div>
            }

            {filterValue === 2 &&
                <div id={"switch_application"} className={"filter_container"}>
                    <div className={"bloc_title_info"}>
                        <p>Retrouvez ici les échanges pour lesquelles vous avez proposé votre échange.</p>
                    </div>

                    <div className={"switch_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={userApplications}>
                                {(appplications) => {
                                    if (appplications.length > 0) {
                                            return (appplications.map((appplication, key) => <SwitchFullBlock {...appplication.switchDuty} key={`switch_${appplication.switchDuty.id}`} showActions={false} />))
                                    } else {
                                        return (<GenericEmptyItem title={"Aucune candidature pour le moment"}/>)
                                    }
                                }}
                            </Await>
                        </Suspense>

                    </div>
                </div>
            }

            {filterValue === 3 &&
                <div id={"switch_history"} className={"filter_container"}>
                    <div className={"bloc_title_info"}>
                        <p>Retrouvez ici les échanges terminés.</p>
                    </div>
                    <div className={"switch_block_wrapper"}>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={allDones}>
                                {(dones) => {
                                    if (dones.length > 0) {
                                        return (dones.map((item, key) => <SwitchFullBlock {...item.switchDuty} key={`switch_${item.switchDuty.id}`} showActions={false} />))
                                    } else {
                                        return (<GenericEmptyItem title={"Aucun échange terminé pour le moment"}/>)
                                    }
                                }}
                            </Await>
                        </Suspense>
                    </div>
                </div>
            }

            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)} forceSliding={true}>
                <AddPermanenceRequest user={user} handleClose={() => {
                    revalidator.revalidate()
                    setShowSlidingPanel(false)
                }}/>
            </SlidingPanel>

            <SlidingPanel show={showSlidingPanelAddNote} handleClose={() => setShowSlidingPanelAddNote(false)}>
                <AddNoteToSwitch handleClose={() => setShowSlidingPanelAddNote(false)} switchId={currentSwitchId}/>
            </SlidingPanel>

            <SlidingPanel show={showSlidingPanelConfirmSwitch} handleClose={() => setShowSlidingPanelConfirmSwitch(false)}>
                <ConfirmSwitchRequest handleClose={() => setShowSlidingPanelConfirmSwitch(false)} switchId={currentSwitchId}/>
            </SlidingPanel>


        </div>
    )
}

export default Switchs