import React from "react";
import {Link} from "react-router-dom";

/**
 * Global component
 * @returns {Element}
 * @constructor
 */
const SubscriptionConfirmation = () => {

    return (
        <>
            <h1>Félicitations !</h1>
            <h2>Votre abonnement est maintenant actif !</h2>
            <div className={"cta_wrapper"}>
                <Link to={"/dashboard"} className={"cta full blue"}>Revenir à l'accueil</Link>
            </div>
        </>
    )
}

export default SubscriptionConfirmation