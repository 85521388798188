import React from "react";

const FilterSearchBar = (props) => {
    const createFilterOptions = () => {
        let htmlFilters = []

        props.filters.forEach((filter, key) => {
            htmlFilters.push(
                <option value={filter.value} key={"seach_filter_option_"+key}>{filter.label}</option>
            )
        })

        return htmlFilters
    }


    return(
        <div className={"searchbar_filter"}>
            {props.searchBar && <input type={"text"} id="searchbar" className={"searchbar"} name={"search"} placeholder={props.searchBarPlaceholder} onChange={props.setSearchValue}/> }
            {props.filters.length > 0 && <select name={"search_filter"} onChange={props.setFilterValue}>
                {createFilterOptions()}
            </select>}
        </div>
    )
}

FilterSearchBar.defaultProps = {
    searchBar : true,
    searchBarPlaceholder : "Rechercher un sujet",
    filters : [],
    setFilterValue : () => console.log("please define setFilterValue"),
    setSearchValue : () => console.log("please define handleSearchChange"),
}

export default FilterSearchBar