import React, {useEffect, useState} from 'react';
import {useChat} from "../../other/Twilio";
import {Link, useNavigate} from "react-router-dom";
import {isMobile} from 'react-device-detect';
import Api from "../../helper/api";
import SingleMatch from "../match/SingleMatch";
import ConversationSingle from "./ConversationSingle";
import {GenericEmptyItem} from "../../other/GenericBlock";
import { formatDistance } from "date-fns";
import { fr } from "date-fns/locale";
import Linkify from 'react-linkify';

export const ConversationBlock = ({conversation, showTitle, onClick}) => {

    const navigate = useNavigate()

    // console.log(conversation.unreadCnt, 'COUNT')
    // console.log(conversation.unreadDot, 'DOT')

    // const message = conversation.messages.items[conversation.messages.items.length - 1].state.body.replace(/\n+/g, ' ').substring(0, 50)
    let lastMessage = conversation.messages.items[conversation.messages.items.length - 1];
    if (lastMessage) {
        lastMessage = lastMessage.state.body.replace(/\n+/g, ' ')
    }

    return(
        <div className={"conversation_block_item"} key={conversation.sid} onClick={onClick}>
            <div className={"picture"}></div>
            <div className={"content"}>
                {showTitle && <div className={"title"}>{conversation.friendlyName}</div>}
                {showTitle && <div className={"subtitle"}>{conversation.friendlyName}</div>}
                {conversation.messages.items.length > 0 && <div className={"last_message"}>
                    <div dangerouslySetInnerHTML={{__html: lastMessage}}></div>
                </div>}
            </div>
            {conversation.lastMessage?.dateCreated && <div className={"extra"}>
                <div className={"time"}>{formatDistance(new Date(conversation.lastMessage.dateCreated), new Date(), { locale: fr })}</div>
                {/*{conversation.unreadCnt > 0 && <span className={"dot"}>{conversation.unreadCnt}</span>}*/}
            </div>}
            {/*{!showTitle && <Link className={"conversation-link-see d-mobile-none"}>Voir</Link>}*/}
        </div>
    )
}

ConversationBlock.defaultProps = {
    conversation : null,
    showTitle : true
}

const Conversations = ({limit = 0}) => {

    const {conversations} = useChat()
    const navigate = useNavigate()
    const api = new Api();
    const [currentConv, setCurrentConv] = useState(null)

    let convs = conversations
    if (limit > 0) {
        convs = conversations.slice(0, limit)
    }
    // console.log(convs)

    useEffect(() => {
        if (!limit && !isMobile && convs.length > 0 && !currentConv) {
            setCurrentConv(convs[0])
        }
    }, [conversations]);

    return (
        <div id={"page_conversation"}>
            <h2>Messagerie</h2>

            <div className={"conversation_wrapper"}>
                <div className={"side-right"}>
                    {!convs || convs.length === 0 && <>
                        <GenericEmptyItem title={"Aucune discussion pour le moment"} />
                    </>}
                    {convs.sort((a, b) => {
                        if (b.lastMessage && a.lastMessage){
                            return b.lastMessage.dateCreated.getTime() > a.lastMessage.dateCreated.getTime() ? 1 : -1
                        }
                        return b.dateUpdated?.getTime() > a.dateUpdated?.getTime() ? 1 : -1
                    }).map((conversation) => <ConversationBlock conversation={conversation} onClick={() => {
                        if (isMobile) {
                            navigate("/messagerie/"+conversation.sid)
                        } else {
                            setCurrentConv(conversation)
                        }
                    }} /> )}
                </div>
                {!isMobile && limit == 0 && <div className={"side-left border-side-left"}>
                    {currentConv !== null && <ConversationSingle conv={currentConv}/>}
                </div>}
            </div>
        </div>
    )

}

export default Conversations