import React, {Suspense, useState} from "react";
import Slider from "react-slick";

import SlidingPanel from "./_sliding_panel";
import FilterButtonBar from "../other/FilterButtonBar";
import AddQuestion from "../slidingContent/AddQuestion";
import AddModelAct from "../slidingContent/AddModelAct";
import FilterSearchBar from "../other/FilterSearchBar";
import {Await, useLoaderData} from "react-router-dom";
import {GenericEmptyItem} from "../other/GenericBlock";
import QuestionBlock from "./question/_QuestionBlock";
import ActBlock from "./act/_ActBlock";

const Community = () => {

    const [filterValue, setFilterValue] = useState(0);
    const [showSlidingPanel, setShowSlidingPanel] = useState(false);

    const carouselSettings = {
        dots : true,
        slidesToShow : 1,
        slidesToScroll : 1
    }

    const dataPromise = useLoaderData()

    return(
        <div id={"community_wrapper"}>

            <div className={"bloc_title_info"}>
                <h2>Bienvenue au sein de la communauté #esterLawyer</h2>
                <p>Cet espace est réservé aux avocats membres d'Esterlaw. Il est destiné à préserver et exprimer le lien de confraternité.</p>
            </div>

            <div id={"aide_intro_wrapper"}>

                <FilterButtonBar filters={[
                    { value : "legal_issues", label : "Questions juridiques"},
                    { value : "model_of_act", label : "Modèles d'actes"},
                ]} setFilterValue={setFilterValue}/>

                {filterValue === 0 &&
                    <div id={"legal_issues_wrapper"} className={"filter_container"}>
                        <div className={"text-center"}>
                            <button className={"cta small blue"} onClick={() => setShowSlidingPanel(true)}>
                                Poser une question
                            </button>
                        </div>

                        {/*<FilterSearchBar searchBar={true} searchBarPlaceholder={"Recherche un sujet, une spécialité ..."}/>*/}

                        <div id={"help_demands"} className={"content_block"}>
                            {/*<h3>Mes Questions</h3>*/}
                            <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                                <Await resolve={dataPromise}>
                                    {(value) => {
                                        const [questions, acts] = value

                                        if (questions.length > 0) {
                                            return (questions.map((question, key) => <QuestionBlock {...question} key={`question_${question.id}`} />))
                                        } else {
                                            return (<GenericEmptyItem title={"Aucune question pour le moment"}/>)
                                        }
                                    }}
                                </Await>
                            </Suspense>
                        </div>
                    </div>
                }
                {filterValue === 1 &&
                    <div id={"model_of_act_wrapper"} className={"filter_container"}>

                        {/*<FilterSearchBar filters={[*/}
                        {/*    { value : "all", label : "Tous les modèles"},*/}
                        {/*]} searchBar={true} searchBarPlaceholder={"Recherche un sujet, une spécialité ..."}/>*/}

                        <div className={"text-center"}>
                            <button className={"cta small blue"} onClick={() => setShowSlidingPanel(true)}>Partager un modèle d'acte</button>
                        </div>

                        <div id={"my_model"} className={"content_block"}>
                            {/*<h3>Modèles d'actes</h3>*/}
                            <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                                <Await resolve={dataPromise}>
                                    {(value) => {
                                        const [questions, acts] = value
                                        if (acts.length > 0) {
                                            return (acts.map((act, key) => <ActBlock {...act} key={`act_${act.id}`} />))
                                        } else {
                                            return (<GenericEmptyItem title={"Aucun modèle d'acte pour le moment"}/>)
                                        }
                                    }}
                                </Await>
                            </Suspense>
                        </div>
                    </div>
                }

            </div>

            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)}>
                {filterValue === 0 && <AddQuestion/> }
                {filterValue === 1 && <AddModelAct/>}
            </SlidingPanel>

        </div>
    )
}

export default Community