import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import Api from "../../helper/api";
import PhoneInput from "react-phone-input-2";
import * as Constant from "../../other/Constant";

const ValidatePhoneScreen = () => {

    //component state
    const [serverError, setServerError] = useState("")
    const navigate = useNavigate()
    const [showPhone, setShowPhone] = useState(false)

    //handle form
    const {register,formState: { errors }, handleSubmit, setValue} = useForm()

    const handleFormSubmit = async (data) => {
        const api = new Api()
        try{
            if (showPhone) {
                await api.postSMS({
                    phone: data.phone
                }).then((response) => {
                    setShowPhone(false)
                })
            } else {
                await api.verifyPhoneNumber(data.code)
                navigate(0)
            }
        } catch(error) {
            setServerError(error.message)
        }

    }

    return(
        <div className={"connection_wrapper"}>
            <div className={"connection_box"}>
                <h1>Bienvenue, dernière étape de securité</h1>
                <p>Renseignez le code de vérification à 4 chiffres envoyé par sms</p>
                <form id={"connection_form"} onSubmit={handleSubmit(handleFormSubmit)}>
                    {!showPhone && <div className={"form_row_wrapper type3"}>
                        <label htmlFor={"code"}>Code de vérification</label>
                        <input type={"text"} name={"code"} id={"code"} placeholder={"Code"}  {...register("code", {required : "Veuillez renseigner le code de validation" })} />
                        <div className={"error"}>{errors.code && errors.code.message}</div>
                    </div>}
                    {showPhone && <div className={"form_row_wrapper type3"}>
                        <input type={"hidden"} name={"phone"} id={"phone"} {...register("phone", {required: "Veuillez renseigner votre numéro de téléphone"})}/>

                        <PhoneInput
                            country={'fr'}
                            onChange={phone => setValue("phone", "+" + phone)}
                            placeholder={"602030405"}
                        />
                        <label htmlFor={"phone"}>Téléphone</label>
                        <div className={"error"}>{errors.phone && errors.phone.message}</div>
                    </div>}
                    <div className={"error text-center"}>{serverError}</div>

                    <div className={"form_row_wrapper type1"}>
                        <input type={"submit"} value={showPhone ? "Renvoyer" : "Vérifier"}/>
                    </div>
                </form>

                {!showPhone && <div className={"connection_link"}>
                    <Link className={"connection_small_link"} to={"#0"} onClick={() => setShowPhone(!showPhone)}>Vous n'avez pas reçu de code ? Renvoyer le code</Link>
                </div>}
                {showPhone && <div className={"connection_link"}>
                    <Link className={"connection_small_link"} to={"#0"} onClick={() => setShowPhone(!showPhone)}>Saisir code</Link>
                </div>}
            </div>
        </div>
    )
}

export default ValidatePhoneScreen