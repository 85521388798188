import React from "react";

const AddPermanenceRequest = () => {

    return (
        <div id={"addPermanencerequest_wrapper"}>
            <div className={"bloc_title_info"}>
                <h2>Créer une demande d’échange de permanance</h2>
                <p>En quelques secondes, décrivez votre besoin.</p>
            </div>

            <div id={"addpermanencerequest_form"} className={"profil_block"}>
                <form>
                    <div className={"form_row_wrapper type3"}>
                        <label>Lieu</label>
                        <input type={"text"} name={"place"} id={"place"} placeholder={"Type here"}/>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Date</label>
                        <input type={"text"} name={"date"} id={"date"} placeholder={"Type here"}/>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Durée estimée</label>
                        <input type={"text"} name={"duration"} id={"duration"} placeholder={"Type here"}/>
                    </div>
                    <div className={"form_row_wrapper type3"}>
                        <label>Domaine de compétence</label>
                        <input type={"text"} name={"skill_category"} id={"skill_category"} placeholder={"Type here"}/>
                    </div>
                    <div className={"text-center cta_wrapper"}>
                        <button className={"cta full blue"}>Publier</button>
                        <button className={"cta full blue reverse"}>Enregistrer</button>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default AddPermanenceRequest