import React, {Suspense, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Api from "../helper/api";
import {GenericEmptyItem} from "../other/GenericBlock";
import {Await} from "react-router-dom";

const AddQuestion = ({handleClose}) => {
    const {register,formState: { errors }, handleSubmit, reset, setValue} = useForm();
    const [dataPromise, setDataPromise] = useState(null)
    const api = new Api();

    useEffect(() => {

        const p = Promise.all([
            api.getQuestionTypes(),
        ])

        setDataPromise(p)
    },[])

    //handle form surbmit
    const handleHelpSubmit = (data) => {

        const params = {
            // type: data.type ? 'api/question_types/' + data.type.id : null,
            description: data.description,
            anonymous: data.anonymous,
        }
        api.postQuestion(params).then((data) => handleClose())
    }

    if (dataPromise === null) {
        return
    }

    return (
        <div id={"addquestion_wrapper"}>
            <div className={"bloc_title_info"}>
                <h2>Poser une question</h2>
            </div>

            <div id={"addhelprequest_form"} className={"profil_block"}>
                <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                    <Await resolve={dataPromise}>
                        {(value) => {
                            const [typesList] = value

                            return (
                                <form onSubmit={handleSubmit(handleHelpSubmit)}>
                                    {/*<div className={"form_row_wrapper type3"}>*/}
                                    {/*    <label>Type</label>*/}
                                    {/*    <select name={"type"} id={"type"}*/}
                                    {/*            defaultValue={""} {...register("type", {required: "Veuillez renseigner cette information"})}>*/}
                                    {/*        <option value="" disabled={true}>Type</option>*/}
                                    {/*        {typesList.map((c, index) => {*/}
                                    {/*            return (<option value={c.id} key={index}>{c.name}</option>)*/}
                                    {/*        })}*/}
                                    {/*    </select>*/}
                                    {/*    <div className="error">{errors.type && errors.type.message}</div>*/}
                                    {/*</div>*/}

                                    <div className={"form_row_wrapper type2"}>
                                        <label>Description</label>
                                        <textarea name={"description"} id={"description"} {...register("description", {required: "Champs obligatoire"})}></textarea>
                                        <div className={"error"}>{errors.description && errors.description.message}</div>
                                    </div>
                                    <div className={"form_row_wrapper"}>
                                        <div className={"checkbox_wrapper checkbox_blue"}>
                                            <input type={"checkbox"} name={"anonymous"} id={"anonymous"} {...register("anonymous")}/>
                                            <label htmlFor={"anonymous"}>Si vous souhaitez poster en anonyme, cocher
                                                cette case</label>
                                        </div>
                                    </div>

                                    <div className={"text-center mb2 mt3"}>
                                        <button className={"cta full blue"}>Publier la question</button>
                                    </div>
                                </form>
                            )
                        }}
                    </Await>
                </Suspense>
            </div>

        </div>
    )
}

export default AddQuestion