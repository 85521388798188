import React, {Suspense, useEffect, useState} from "react";

import {Await, useLoaderData,  useOutletContext} from "react-router-dom";

import {Link} from "react-router-dom";
import {GenericEmptyItem, SingleMatchBlock} from "../other/GenericBlock";
import {useSetState} from "react-use";
import {filterDuplicateObjectInLinkings, sortMissionByStatus} from "../helper/missionHelper";
import * as Constant from "../other/Constant";
import {PROFIL_TYPE_RECRUTEUR} from "../other/Constant";
import ItemLabel from "../other/ItemLabel";
import MissionBlock from "./mission/_MissionBlock";
import HelpBlock from "./help/_HelpBlock";
import SwitchBlock from "./switch/_SwitchBlock";
import Conversations from "./message/Conversations";
import {isMobile} from "react-device-detect";

/**
 * Dashboard Data block with title and content
 * @param title
 * @param moreLink
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const DashboardBlock = ({title, moreLink, children}) => {
    return(
        <div className={"dashboard_block_wrapper"}>
            {moreLink && <Link className={"dashboard_block_more_link"} to={moreLink}>Tout voir</Link>}
            {title && <h2>{title}</h2>}
            {children}
        </div>
    )
}

/**
 * Dashboard Data statistics
 * @param title
 * @param promise
 * @param value
 * @returns {JSX.Element}
 * @constructor
 */
const DashboardStatBlock = ({title, promise, value = null}) => {
    return(
        <div className={"dashboard_item_stat"}>
            <div>
                <Suspense fallback={"..."}>
                    <Await resolve={promise}>
                        {(data) => value === null ?data.length:value}
                    </Await>
                </Suspense>
            </div>
            <span>{title}</span>
        </div>
    )
}

const Dashboard = () => {

    //get loader data
    //const { missions, helps, switchs, pendingMatchs, pendingHelpMatchs, pendingSwitchMatchs } = useLoaderData()
    const loaderPromises = useLoaderData()
    const {user} = useOutletContext();

    const [missionsState, setMissionsState] = useSetState()
    const [matchState, setMatchState] = useState(0)

    useEffect(() => {

        if(user.profileType === PROFIL_TYPE_RECRUTEUR){
            //format data for profil type Recruteur

            //missions datas
            Promise.all([loaderPromises.missions.active, loaderPromises.missions.closed]).then((missionsPromise) =>{
                const [active, closed] = missionsPromise

                setMissionsState({
                    active : active,
                    closed : closed
                })
            })
        } else {
            //format data for profile type candidat

            //missions datas
            Promise.all([loaderPromises.missions.active, loaderPromises.missions.agreement]).then((missionsPromise) =>{
                const [active, agreement] = missionsPromise

                setMissionsState({
                    active : active,
                    agreement : agreement
                })
            })
        }
    }, [loaderPromises]);

    return(
        <div className={"dashboard_page_wrapper"} data-profil={user?.profileType}>
            <div className={"dashboard-left"}>
                <div className={"dashboard-stats-wrapper"}>
                    {user.profileType === Constant.PROFIL_TYPE_RECRUTEUR &&
                        <div className={"dashboard_block_wrapper dashboard-stats-numbers"}>
                            <div className={"bloc_title_info"}>
                                <h2>Bonjour {user.firstname} !</h2>
                                <p className={"user_profile_type"}>Je publie des missions</p>
                            </div>

                            <div id={"dashboard_stat_block"}>
                                <DashboardStatBlock title={"Mes matchs"} promise={loaderPromises.allMatchsPromise}/>
                                <DashboardStatBlock title={"En discussion"} promise={loaderPromises.acceptedMatchPromise}/>
                                <DashboardStatBlock title={"Mes accords"} promise={loaderPromises.agreementMatchPromise}/>
                            </div>
                        </div>
                    }

                    {user.profileType === Constant.PROFIL_TYPE_CANDIDAT &&
                        <div className={"dashboard_block_wrapper dashboard-stats-numbers"}>
                            <div className={"bloc_title_info"}>
                                <h2>Bonjour {user.firstname} !</h2>
                                <p className={"user_profile_type"}>Je propose mes compétences</p>
                            </div>

                            <div id={"dashboard_stat_block"}>
                                <DashboardStatBlock title={"Mes matchs"} promise={loaderPromises.allMatchsPromise}/>
                                <DashboardStatBlock title={"En discussion"} promise={loaderPromises.discussMatchPromise}/>
                                <DashboardStatBlock title={"Mes accords"} promise={loaderPromises.agreementMatchPromise}/>
                            </div>
                        </div>
                    }

                    <DashboardBlock>
                        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                            <Await resolve={Promise.all([loaderPromises.pendingMatchs, loaderPromises.helps, loaderPromises.switchs])} errorElement={<p>Error loading Data !</p>}>
                                {(linkings) => {
                                    const missionLinkings = linkings[0]
                                    const helpLinkings = linkings[1]
                                    const switchLinkings = linkings[2]
                                    const allNewLinking = missionLinkings.length + (helpLinkings && helpLinkings.length > 0 ? helpLinkings?.filter(obj => obj.step === 1).length : 0) + (switchLinkings && switchLinkings.length > 0 ? switchLinkings?.filter(obj => obj.step === 1).length : 0)
                                    if (allNewLinking > 0) {
                                        return <SingleMatchBlock count={allNewLinking} link={"/matchs"} user={user} />
                                    } else {
                                        return <GenericEmptyItem title={"Bientôt vos prochains matchs"}/>
                                    }
                                }}
                            </Await>
                        </Suspense>
                    </DashboardBlock>
                </div>

                <DashboardBlock title={"Missions"} moreLink={"/missions"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={loaderPromises.missions.active} errorElement={<p>Error loading Data !</p>} >
                            {(missions) => {
                                if (missions.length > 0)
                                    return (missions.map((mission, key) => <MissionBlock {...mission}/>))
                                else
                                    return (<GenericEmptyItem title={"Bientôt vos prochaines missions"}/>)
                            }}
                        </Await>
                    </Suspense>
                </DashboardBlock>

                <DashboardBlock title={"Aides"} moreLink={"/aides"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={Promise.all([loaderPromises.helps, loaderPromises.myHelps])} errorElement={<p>Error loading Data !</p>} >
                            {(helps) => {

                                //array 1 -> help linkings
                                //array 2 -> my help

                                //order help linkings by step value DESC AND remove duplicate help in linkings
                                const helpFromLinkin = filterDuplicateObjectInLinkings(helps[0], true)
                                helps = helpFromLinkin.concat(helps[1])

                                if(helps.length > 0)
                                    return helps.map((help, key) => (<HelpBlock {...help}/>))
                                else
                                    return <GenericEmptyItem title={"Bientôt vos prochaines aides"}/>
                            }}
                        </Await>
                    </Suspense>
                </DashboardBlock>

                <DashboardBlock title={"Échanges"} moreLink={"/echanges"}>
                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={Promise.all([loaderPromises.switchs, loaderPromises.mySwitchs])} errorElement={<p>Error loading Data !</p>} >
                            {(switchs) => {

                                const switchFromLinkin = filterDuplicateObjectInLinkings(switchs[0], true)
                                switchs = switchFromLinkin.concat(switchs[1])

                                if(switchs.length > 0){
                                    return( switchs.map((s, key) => <SwitchBlock {...s}/>) )
                                }else{
                                    return( <GenericEmptyItem title={"Bientôt vos prochains échanges"}/> )
                                }
                            }}
                        </Await>
                    </Suspense>
                </DashboardBlock>
            </div>
            {!isMobile && <div className={"dashboard-right"}>
                <DashboardBlock title={"Profil"}>
                    <div className={"user_picture"}>
                        <div className={"picture"}>
                            {/*{!user.imageName && <div>{user.firstname.charAt(0)}{user.lastname.charAt(0)}</div>}*/}
                            {user?.imageName && <img src={process.env.REACT_APP_API_URL+"/"+user.imageName}/> }
                        </div>
                        <div className={"name"}>{user?.firstname} {user?.lastname}</div>
                        <Link to={"/profile"} className={"cta full blue"}>Voir profil</Link>
                    </div>
                </DashboardBlock>
                <DashboardBlock>
                    <Conversations limit={5} />
                </DashboardBlock>
            </div>}
        </div>
    )
}

export default Dashboard
