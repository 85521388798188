import {useRouteError} from "react-router-dom";
import { ContextProvider } from '../userAuth/AuthContext';
import Header from "../header";
import React, {useEffect} from "react";
import history from "../helper/history";

const ErrorPage = () => {
    const error = useRouteError();

    //if error status = 401 -> disconnect user and redirect
    useEffect(() => {

        if(error.response) {
            switch (error.response.status) {
                //Expired Token
                case 401 :
                    localStorage.clear()
                    //TODO faire plus propre
                    window.location.href = "/connexion"
                    break;
            }
        }
    },[])

    return (

        <div className="page_wrapper" id={"page_404_wrapper"}>
            <ContextProvider>
            <Header/>
            <div className="content_wrapper" id={"content_404_wrapper"}>
                <div id="404_error_wrapper">
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error.statusText || error.message}</i>
                    </p>
                </div>
            </div>
            {/*<Footer/>*/}
            </ContextProvider>
        </div>
    );
}

export default ErrorPage