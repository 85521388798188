import React, {useEffect, Suspense, useState} from "react";
import Api from "../../helper/api";
import {Await, useLoaderData, useOutletContext, useRevalidator} from "react-router-dom";
import {GenericEmptyItem} from "../../other/GenericBlock";
import ProfilDataBlock from "../../other/ProfilDataBlock";
import {CtaLike, CtaMessage, CtaReturn} from "../../other/Cta";
import SingleItemHeader, {OtherSingleItemHeader} from "../_singleItem/SingleItemHeader";
import SingleItemMatch from "../_singleItem/SingleItemMatch";
import SlidingPanel from "../_sliding_panel";
import SingleItemDetails from "../_singleItem/SingleItemDetails";
import {PROFIL_TYPE_CANDIDAT, PROFIL_TYPE_RECRUTEUR, STEP_AGREEMENT, STEP_PENDING, STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED, STEP_SENDER_REFUSED} from "../../other/Constant";
import ItemLabel from "../../other/ItemLabel";
import moment from "moment";
import SingleItemMessages from "../_singleItem/SingleItemMessages";
import SingleItemCollaborateur from "../_singleItem/SingleItemCollaborateur";
import CreateCompany from "../../slidingContent/createCompany";
import {useChat} from "../../other/Twilio";

const SingleMatch = (props) => {

    //const [singleMatch, setSingleMatch] = useState([])
    const api = new Api()
    const revalidator = useRevalidator()
    const [matchConfirmation, setMatchConfirmation] = useState(null)

    console.log("TEST TEST MATCH")

    let {match} = useLoaderData()

    if (props.match) {
        match = props.match
    } else {
    }
    // console.log(match, "CHECK MATCH DATA")
    const {user} = useOutletContext()

    const updateMatchStep = (match_id, step) => {
        api.updateMatch(match_id,step).then(() => {
            revalidator.revalidate()
            setMatchConfirmation(step)
        })
    }

    /*Accept the match*/
    const acceptMatch = (match_id) => updateMatchStep(match_id,3)

    /*Decline the match*/
    const declineMatch = (match_id) => updateMatchStep(match_id,4)

    /*Decline the match*/
    const agreementMatch = (match_id) => updateMatchStep(match_id,4)

    useEffect(() => {

    }, []);

    return(
        <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
            <Await resolve={match} errorElement={<p>Error loading Data !</p>}>
                {(match) => {
                    console.log("match", match)
                    if (user.profileType === PROFIL_TYPE_RECRUTEUR) {
                        return(<SingleMatchCurrent match={match} user={user} acceptMatch={(match_id) => updateMatchStep(match_id, STEP_SENDER_ACCEPTED)} declineMatch={(match_id) => updateMatchStep(match_id,STEP_SENDER_REFUSED)} agreementMatch={(match_id) => updateMatchStep(match_id,STEP_AGREEMENT)} />)
                    } else {
                        return(<SingleMatchCandidat match={match} user={user} acceptMatch={(match_id) => updateMatchStep(match_id, STEP_RECIPIENT_ACCEPTED)} />)
                    }
                }}
            </Await>
            <SlidingPanel show={matchConfirmation !== null} handleClose={() => {
                setMatchConfirmation(null)
                window.location.reload()
            }}>
                <div>
                    <div className={"bloc_title_info"}>
                        {matchConfirmation === 1 && <>
                            <h2>Bravo !</h2>
                            <p>Votre candidature vien d'être envoyée.</p>
                        </>}
                        {matchConfirmation === 2 && <>
                            <h2>Offre de mission refusée</h2>
                        </>}
                        {matchConfirmation === 3 && <>
                            <h2>Bravo !</h2>
                            <p>Vous venez d'accepter de discuter avec ce candidat.</p>
                        </>}
                        {matchConfirmation === 4 && <>
                            <h2>Candidature non retenue.</h2>
                        </>}
                        {matchConfirmation === 5 && <>
                            <h2>Bravo !</h2>
                            <p>Vous venez de passer à l'accord.</p>
                        </>}
                    </div>
                    <div className={"text-center mb2 mt3"}>
                        <button className={"cta full blue"} onClick={() => {
                            setMatchConfirmation(null)
                            window.location.reload()
                        }}>Fermer</button>
                    </div>
                </div>
            </SlidingPanel>
        </Suspense>
    )
}

const SingleMatchCurrent = ({match, user, acceptMatch, declineMatch, agreementMatch}) => {

    return(
        <div id={"single_match_wrapper"} className={"single_match_recruteur"}>
            <div className="cta_wrapper d-mobile-none">
                <CtaReturn />
            </div>

            <div className="single_match_block" id={"match_header"}>
                <CtaReturn />
                <div>
                    {(match.step === STEP_SENDER_ACCEPTED || match.step === STEP_AGREEMENT) && <CtaMessage match={match} />}
                    <CtaLike type={"match"} item={match} userId={user.id}/>
                </div>
            </div>

            {match.step === STEP_RECIPIENT_ACCEPTED &&
                <div className="single_match_block" id={"match_details"}>
                    <h1>Profil</h1>
                    {match.user.createdAt && <h5>Membre actif depuis {moment(match.user.createdAt).format('DD/MM/YYYY')}</h5>}
                    {/*<h2>Avocat spécialisé en droit fiscal</h2>*/}

                    <div className="content">
                        <div className={"picto bar"}>Bareaux : {match.user.bar?.name}</div>
                        <div className={"picto experience"}>Expérience : {match.user.experience?.name}</div>
                        <div className={"picto languages language"}>
                            <div>Langues :&nbsp;{match.user.languages.map((item) => <div>{`${item.name} / `}</div>)}</div>
                        </div>
                        <div className={"picto countries language"}>
                            <div>Expertise internationale :&nbsp;{match.user.countries.map((item) => <div>{`${item.name} / `}</div>)}</div>
                        </div>
                    </div>

                </div>
            }

            {(match.step === STEP_SENDER_ACCEPTED || match.step >= STEP_AGREEMENT) &&
                <div className="single_match_block" id={"match_details"}>
                    <h2>Profil</h2>

                    <div className="content">
                        <div className={"picto user"}>{match.user.firstname} {match.user.lastname}</div>
                        <div className={"picto mail"}>{match.user.email}</div>
                        <div className={"picto phone"}>{match.user.phone}</div>
                        <div className={"picto location"}>{match.user.location}</div>
                        <div className={"picto bar"}>{match.user.bar?.name}</div>
                        <div className={"picto experience"}>{match.user.experience?.name}</div>
                        <div className={"picto languages language"}>
                            Langues : {match.user.languages.map((item) => <div>{`${item.name} / `}</div>)}
                        </div>
                        <div className={"picto countries language"}>
                            Expertise internationale : {match.user.countries.map((item) => <div>{`${item.name} / `}</div>)}
                        </div>
                    </div>
                </div>
            }

            {match.user.description && <div className="single_match_block" id={"match_presentation"}>
                <h2>Présentation</h2>
                <p>{match.user.description}</p>
            </div>}

            {match.user.skills.length > 0 && <div className="single_match_block" id={"match_skill"}>
                <h2>Compétences</h2>
                {match.user.skills.map((item, i) => <ProfilDataBlock title={item.area.name} experience={item.experience.name} international={item.countries} description={item.description} subareas={item.subareas}/>)}
            </div>}

            {match.user.specializations.length > 0 && <div className="single_match_block" id={"match_specialization"}>
                <h2>Spécialisations</h2>
                {match.user.specializations.map((item, i) => <ProfilDataBlock key={`specialization_data${i}`} title={item.specialization.name} experience={item.experience.name} description={item.description}/>)}
            </div>}

            {match.user.industries.length > 0 && <div className="single_match_block" id={"match_industry"}>
                <h2>Secteurs d'activité</h2>
                {match.user.industries.map((item, i) => <ProfilDataBlock key={`secteur_data${i}`} title={item.industry.name} experience={item.experience ? item.experience.name : null} international={item.countries}
                                                                         description={item.description}/>)}
            </div>}

            {match.user.jobs.length > 0 && <div className="single_match_block" id={"match_job"}>
                <h2>Parcours professionnel</h2>
                {match.user.jobs.map((item, i) => <ProfilDataBlock key={`job_data${i}`} title={item.title} description={item.description}/>)}
            </div>}

            {match.user.educations.length > 0 && <div className="single_match_block" id={"match_formation"}>
                <h2>Formation et certifications</h2>
                {match.user.educations.map((item, i) => <ProfilDataBlock key={`education_data${i}`} title={item.title} description={item.description}/>)}
            </div>}


            <div className="single_match_block" id={"cta_wrapper"}>
                {match.step === STEP_RECIPIENT_ACCEPTED &&
                    <>
                        <button className={"cta blue full"} onClick={() => acceptMatch(match.id)}>J'accepte et souhaite discuter</button>
                        <button className={"cta blue reverse full"} onClick={() => declineMatch(match.id)}>Non merci</button>
                    </>
                }
                {match.step === STEP_SENDER_ACCEPTED && <button className={"cta blue full"} onClick={() => agreementMatch(match.id)}>Passer à l'accord</button>}
            </div>

        </div>
    )
}

const SingleMatchCandidat = ({match, user, acceptMatch, declineMatch}) => {

    //filter
    const [showSlidingPanelDetails, setShowSlidingPanelDetails] = useState(false);

    console.log(match, 'MATCH FROM CANDIDAT')

    return(
        <div className={"single_item_wrapper"}>
            <div className={"side-left d-mobile-none"}>
                <div className={"single_mission_header"}>
                    <div className={"cta_wrapper"}>
                        <CtaReturn/>
                    </div>
                    <SingleItemDetails singleItem={match.mission} detail={false}/>
                </div>
            </div>
            <div className={"side-right d-mobile-none"}>
                <div id={"single_help_cta"} className={"single_item_cta_wrapper"}>
                    {match.step === STEP_PENDING && <>
                        <button className={"cta blue full"} onClick={() => acceptMatch(match.id)}>Je suis intéressé(e) et souhaite discuter</button>
                        <button className={"cta blue reverse full"} onClick={() => declineMatch(match.id)}>Non merci</button>
                    </>}
                    {match.step === STEP_RECIPIENT_ACCEPTED && <button className={"cta transparent full disabled"}>Vous avez candidaté cette mission</button>}
                    {/*{match.step === STEP_SENDER_ACCEPTED && <button className={"cta blue full"} onClick={() => agreementMatch(match.id)}>Passer à l'accord</button>}*/}
                </div>
            </div>

            <div className={"d-desktop-none"}>
                <div className="single_match_block" id={"match_header"}>
                    <CtaReturn/>
                    {(match.step === STEP_SENDER_ACCEPTED || match.step === STEP_AGREEMENT) && <CtaMessage match={match}/>}
                    {/*<CtaLike type={"match"} item={match} userId={user.id}/>*/}
                </div>
                <div className="single_match_block">
                    <h2>{match.mission.title}</h2>
                </div>

                <div className={"other_single_item_header"}>
                    {/*{(match.step === STEP_SENDER_ACCEPTED || match.step >= STEP_AGREEMENT) &&*/}
                    {/*    <div className="single_match_block" id={"match_details"}>*/}
                    {/*        <h2>Profil</h2>*/}

                    {/*        <div className="content">*/}
                    {/*            <div className={"picto user"}>{match.user.firstname} {match.user.lastname}</div>*/}
                    {/*            <div className={"picto mail"}>{match.user.email}</div>*/}
                    {/*            <div className={"picto phone"}>{match.user.phone}</div>*/}
                    {/*            <div className={"picto location"}>{match.user.location}</div>*/}
                    {/*            <div className={"picto bar"}>{match.user.bar?.name}</div>*/}
                    {/*            <div className={"picto experience"}>{match.user.experience?.name}</div>*/}
                    {/*            <div className={"countries picto language"}>*/}
                    {/*                {match.user.countries.map((item) => <div>{item.name}</div>)}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    <div className={"single_match_header"}>
                        {match.mission.type.code && <ItemLabel code={match.mission.type.code} type={match.mission.type.name}/>}

                        <div className={"single_match_header_detail"}>
                            {match.mission.type &&
                                <div className={"other_single_item_data"}>
                                    <h3>{match.mission.type.name}</h3>
                                </div>
                            }
                            {match.mission.bar &&
                                <div className={"other_single_item_data"}>
                                    <div className={"picto bar"}>{match.mission.bar.name}</div>
                                </div>
                            }
                            {match.mission.experience &&
                                <div className={"other_single_item_data"}>
                                    <div className={"picto experience"}>{match.mission.experience.name}</div>
                                </div>
                            }
                            {match.mission.location &&
                                <div className={"other_single_item_data"}>
                                    <div className={"picto location"}>{match.mission.location}</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={"content"}>
                        {match.mission.description &&
                            <div className="single_match_block">
                                <h2>Présentation</h2>
                                <p>{match.mission.description}</p>
                            </div>
                        }
                        {match.mission.estimatedBudget &&
                            <div className={"single_match_block"}>
                                <h2>Budget prévisionel</h2>
                                <p>{match.mission.estimatedBudget} €</p>
                            </div>
                        }
                        {match.mission.estimatedDuration &&
                            <div className="single_match_block">
                                <h2>Durée estimée</h2>
                                <p>{match.mission.estimatedDuration.name}</p>
                            </div>
                        }
                        {match.mission.type &&
                            <div className={"single_match_block"}>
                                <h2>Type</h2>
                                <p>{match.mission.type.name}</p>
                            </div>
                        }
                        {match.mission.collaborationType &&
                            <div className={"single_match_block"}>
                                <h2>Mode de collaboration</h2>
                                <p>{match.mission.collaborationType.name}</p>
                            </div>
                        }
                        {/*{match.mission.skills.length > 0 && <div className="single_match_block" id={"match_skill"}>*/}
                        {/*    <h2>Compétences</h2>*/}
                        {/*    {match.mission.skills.map((item, i) => <ProfilDataBlock title={item.area.name} experience={item.experience.name} international={item.countries} description={item.description} subareas={item.subareas}/>)}*/}
                        {/*</div>}*/}

                        {match.mission.specializations.length > 0 && <div className="single_match_block" id={"match_specialization"}>
                            <h2>Spécialisations</h2>
                            {match.mission.specializations.map((item, i) => <ProfilDataBlock key={`specialization_data${i}`} title={item.specialization.name} experience={item.experience.name} description={item.description}/>)}
                        </div>}

                        {match.mission.industries.length > 0 && <div className="single_match_block" id={"match_industry"}>
                            <h2>Secteurs d'activité</h2>
                            {match.mission.industries.map((item, i) => <ProfilDataBlock key={`secteur_data${i}`} title={item.industry.name} experience={item.experience ? item.experience.name : null} international={item.countries}
                                                                                     description={item.description}/>)}
                        </div>}
                    </div>
                </div>
                <div id={"single_help_cta"} className={"single_item_cta_wrapper"}>
                    {match.step === STEP_PENDING && <>
                        <button className={"cta blue full"} onClick={() => acceptMatch(match.id)}>Je suis intéressé(e) et souhaite discuter</button>
                        <button className={"cta blue reverse full"} onClick={() => declineMatch(match.id)}>Non merci</button>
                    </>}
                    {match.step === STEP_RECIPIENT_ACCEPTED && <button className={"cta transparent full disabled"}>Vous avez candidaté cette mission</button>}
                    {/*{match.step === STEP_SENDER_ACCEPTED && <button className={"cta blue full"} onClick={() => agreementMatch(match.id)}>Passer à l'accord</button>}*/}
                </div>
            </div>
            <SlidingPanel show={showSlidingPanelDetails} handleClose={() => setShowSlidingPanelDetails(false)} forceSliding={true}>
                <SingleItemDetails singleItem={match}/>
            </SlidingPanel>
        </div>
    )
}

export default SingleMatch