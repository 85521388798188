import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import Api from "../../helper/api";

const OptionScreenChoice = (props) => {

    //component state
    const [serverError, setServerError] = useState("")
    const navigate = useNavigate()

    //handle form
    const {register,formState: { errors }, handleSubmit} = useForm()

    const handleFormSubmit = async (data) => {

        const api = new Api()

        try{
            await api.UpdateUser(props.user.id, {
                mission : data.type.includes("mission"),
                skill : data.type.includes("skill"),
            })

            return navigate(0)
        }catch(error){
             setServerError(error.message)
        }

    }

    //checkboxgroup validation
    const handleCheckboxValidation = (data) => {

        if(Array.isArray(data) && data.length !== 0 )
            return true

        return false;
    }

    return(
        <div className={"connection_wrapper"}>
            <div className={"connection_box"}>
                <div className={"choice-header-img"} />
                <h1>Que souhaitez-vous ?</h1>
                <p>Optez pour votre besoin prioritaire (ou les 2) ! Il est possible de modifier vos préférences dans votre espace compte.</p>
                <form id={"type_registration_form"} onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className={"form_row_wrapper type1"}>
                        <div className={"checkbox_wrapper checkbox_cta"}>
                            <input type={"checkbox"} name={"type"} id={"type_mission"} value={"mission"} {...register("type",{validate : handleCheckboxValidation})}/>
                            <label htmlFor={"type_mission"}>Proposer des missions</label>
                        </div>
                    </div>
                    <div className={"form_row_wrapper type1"}>
                        <div className={"checkbox_wrapper checkbox_cta"}>
                            <input type={"checkbox"} name={"type"} id={"type_skill"} value={"skill"} {...register("type",{validate : handleCheckboxValidation})}/>
                            <label htmlFor={"type_skill"}>Proposer mes compétences</label>
                        </div>
                    </div>

                    <div className={"jumbotron"}>
                        <div className={"jumbotron_title"}>Aide confraternelle</div>
                        <div className={"jumbotron_content"}>
                            Vous avez accès gratuitement à l'aide confraternelle & communauté.
                        </div>
                    </div>

                    <div className={"error text-center"}>{serverError}</div>

                    <div className={"error text-center"}>
                        {errors.type && "Veuillez sélectionner les actions que vous souhaitez"}
                    </div>

                    <div className={"form_row_wrapper type1"}>
                        <input type={"submit"} value={"Commencer"}/>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default OptionScreenChoice