import React, {useEffect, useLayoutEffect, useState} from "react";
import MultiSelect from "./multiSelect";
import {useForm} from "react-hook-form";
import Api from "../helper/api";
import {useSetState} from "react-use";
import {useDidUpdate} from "../helper/hook";

const FormExperience = ({user, onFormSubmit}) => {

    //react hook form
    const {register,formState: { errors }, trigger, handleSubmit, reset, getValues, setValue} = useForm();

    //api
    const api = new Api();

    //handle multiselect
    const [countries, setCountries] = useState([])
    const [languages, setLanguages] = useState([])

    //Form State
    const [formData, setFormData] = useSetState({
        countryList : [],
        langList : [],
        experienceYear : [],
        updateUserField : false
    })

    //OnComponentDidMount -> Fetch form Data
    useLayoutEffect( () => {
        const fetchData = async () => {

            const countryListData = await api.getCountryList();
            const experienceYear = await api.getExperienceYearList();
            const langList = await api.getLanguageList();

            setFormData({
                countryList: countryListData,
                experienceYear: experienceYear,
                langList : langList,
                updateUserField : true
            })
        }
        fetchData()
    }, [])

    //Set user values
    useEffect(() => {

        if(!formData.updateUserField)
            return

        setValue("experience",user.experience? user.experience.id:"")
        setCountries(user.countries)

        setFormData({
            updateUserField : false
        })

    },[formData.updateUserField])

    //form submit
    const handleFormSubmit = async () => {

        const is_valid = await trigger()

        if(is_valid){
            const data = getValues()
            data.countries = countries
            onFormSubmit(data)
        }

    }


    return(
        <div className={"form"}>
            <div className={"form_row_wrapper type3"}>
                <label>Expérience</label>
                <select name={"experience"} id={"experience"} defaultValue={""} {...register("experience", {
                    required: "Veuillez renseigner cette information"
                })}>
                    <option value={""} disabled={true}>Expérience</option>
                    {formData.experienceYear.map((c, index) => {
                        return (<option value={c.id} key={index}>{c.name}</option>)
                    })}
                </select>
                <div className="error">{errors.experience && errors.experience.message}</div>
            </div>

            <div className={"form_row_wrapper type3 multiselect"}>
                <label>Langues</label>
                <MultiSelect name={"langues"} id={"langues"} placeholder={"Langues"} options={formData.langList.map(item => ({label: item.name, value: item.id}))} isMulti={true} closeMenuOnSelect={false} defaultValues={languages} getValues={setLanguages}/>
            </div>

            <div className={"form_row_wrapper type3 multiselect"}>
                <label>Expertise internationale</label>
                <MultiSelect name={"expertise"} id={"expertise"} placeholder={"Expertise internationale"} options={formData.countryList.map(item => ({label: item.name, value: item.id}))} isMulti={true} closeMenuOnSelect={false} defaultValues={countries} getValues={setCountries}/>
            </div>

            <div className={"cta_wrapper text-right"}>
                <button className={"cta full blue"} onClick={handleSubmit(handleFormSubmit)}>Enregistrer les modifications</button>
            </div>
        </div>
    )
}

FormExperience.defaultProps = {
    user: {},
    onFormSubmit: (data) => console.log("onFormSubmit is not defined -> ", data)
}

export default FormExperience