//dummy missions
import Api from "../helper/api";

/**
 * Return all user missions list
 * @returns {{missions: [{date: string, duration: string, unread_match: string, notification: string, title: string, body: string, deadline: string, people: string, status: string},{date: string, duration: string, unread_match: string, notification: string, title: string, body: string, deadline: string, people: string, status: string},{date: string, duration: string, unread_match: string, notification: string, title: string, body: string, deadline: string, people: string, status: string}]}}
 */
export const getHelps = async (context) => {

    const api = new Api();
    let helpsPromise = api.getHelps(true,false, null);
    let urgentHelpsPromise = api.getHelps(true,false, true);
    let userHelpsPromise = api.getHelps(true,false, null, context.state.user.id);
    let userApplicationsPromise = api.getHelpLinkings(null, [1, 3, 4]);

    return {
        helps : helpsPromise,
        urgentHelps : urgentHelpsPromise,
        userHelps : userHelpsPromise,
        userApplications : userApplicationsPromise,
        allDones: []
    };
}

/**
 * Return data for current mission
 * @param params - contain missionId
 */
export const getHelp = async ({params}) => {

    const api = new Api()
    const helpPromise = api.getHelp(params.helpId)

    return {
        helpPromise : helpPromise
    }
}

/**
 * Return Help linking List for a specific mission
 * @param params - contain missionId
 */
export const getHelpMatchs = async ({params}) => {

    const api = new Api()
    const matchsPromise = api.getHelpLinkings(params.helpId, null, true)

    return {
        type : "aide",
        matchs : matchsPromise
    }
}