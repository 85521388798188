import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../userAuth/AuthContext";
import {GetHelpDate, GetHelpTime} from "../../helper/other";

export const QuestionBlock = (props) => {

    const {state} = useContext(AuthContext)
    const navigate = useNavigate()

    const getTitle = () => {
        if (props.anonymous === true) {
            if (props.user.skills?.length > 0) {
                const skill = props.user.skills[0]
                if (skill) {
                    return skill.area.name
                }
            }
            return 'Membre'
        }
        return props.user?.firstname + ' ' + props.user?.lastname

    }

    return (
        <div className={"dashboard_block question_block"}>
            <div className={"title"}>{getTitle()}</div>
            {/*<div className={"date"}><span>@JohnDoe</span>, 5 hours ago</div>*/}
            <div className={"description"}>{props.description}</div>
            <div className={"stat"}>
                <div>{props.likes.length}</div>
                <div>{props.answers.length}</div>
                {/*<div>*/}
                {/*    <span>Droit des entreprises</span>*/}
                {/*    <span className={"more"}>+1</span>*/}
                {/*</div>*/}
            </div>
        </div>
    // <div className={`question_block_item full`}>
    //         <div className={"question_header"}>
    //             {/*<div className={"date"}>Publié : {GetHelpDate(props.createdOn)}</div>*/}
    //             <div className={"title"}>{getTitle()}</div>
    //         </div>
    //         <div className={"description"}>
    //             {props.description}
    //         </div>
    //         {/*<div className={"footer"}>*/}
    //         {/*    {props.description}*/}
    //         {/*</div>*/}
    //     </div>

    )

}

QuestionBlock.defaultProps = {
    title: "-",
    description: "-",
    answers: [],
    createdOn: "1970-01-01 00:00:00",
}

export default QuestionBlock