import {createContext, useEffect} from "react";
import { useSetState } from 'react-use';
import {useNavigate} from "react-router-dom";

import Api from "../helper/api";

export const AuthContext = createContext(null);

const initialState = {
    isReady : false,
    isLoggedIn : false,
    token : "",
    user : "",
    newNotifications: false,
    notifications: [],
    linkings: []
}

export const ContextProvider = (props) => {

    const [state, setState] = useSetState(initialState);
    const setUserLoginState = (isLoggedIn, user) => setState({isLoggedIn,user, isReady : true})

    //api call
    const api = new Api();

    //update user object
    const updateUserInGlobalContext = (user) => setState({user : user})

    //login / logout
    const login = async (email,password) => {

        //get user token
        const tokenData = await api.getUserToken({email : email, password : password})
        localStorage.setItem("user",JSON.stringify({ token: tokenData.token }));

        const user = await api.getUser();
        setUserLoginState(true, user)

        redirectUser(true)
    }

    const logout = () => {
        localStorage.removeItem("user");
        window.location.href = "/connexion"
    }

    //Redirect user after login
    const redirectUser = (toDashboard = false) => {

        const privateRoute = localStorage.getItem("privateRoute");

        if (privateRoute){
            //navigate(privateRoute);
            localStorage.removeItem("privateRoute");
            window.location.href = privateRoute
        } else if(toDashboard){
            // console.log(state.user, 'CHECK USER')
            // if (state.user && state.user.updatedAt) {
            //
            // } else {
                window.location.href = "/dashboard"
            // }
        }
    }

    const setNewNotifications = (value) => setState({newNotifications: value})
    const setNotifications = (notifications) => setState({notifications : notifications})

    const setLinkings = (linkings) => setState({linkings : linkings})

    //RELOAD saved state
    useEffect(() => {
        const reloadUserDataFromStorage = async () => {
            //reload prev context if exist
            const userInfo = localStorage.getItem("user");

            if(userInfo){
                const data = JSON.parse(userInfo)

                if(data.token){
                    const user = await api.getUser()
                    setUserLoginState(true, user)
                    redirectUser()
                }
                else{
                    logout()
                    setState({isReady: true})
                }
    
    
            }else{
                //navigate("/connexion")
                setState({isReady: true})
            }
        }
        reloadUserDataFromStorage()

    }, []);

    return(
        <AuthContext.Provider value={{state,login,logout,updateUserInGlobalContext, setNewNotifications, setNotifications, setLinkings}}>
            {state.isReady && props.children}
        </AuthContext.Provider>
    )
}
