import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import ItemLabel from "../../other/ItemLabel";
import {useSetState} from "react-use";
import switchs from "../switch/Switchs";
import {STEP_AGREEMENT, STEP_DONE, STEP_PENDING, STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED} from "../../other/Constant";

const SponsorshipBlock = (props) => {

    const navigate = useNavigate();
    const [state, setState] = useSetState({
        completion : "",
        status : "..."
    })

    useEffect(() => {
        console.log(props)
    },[])

    return(
        <div className={`mission_block_item`}>
            {/*{props.type.code && <ItemLabel code={props.type.code} type={props.type.name}/>}*/}
            <div className={"content"}>
                <div className={"title"}>{props.firstname} {props.lastname}</div>
                <div className={"description"}>
                    <span className={"reference"}>{props.email}</span>
                    <span className={"reference"}>{props.phone}</span>
                    {/*{state.status !== "" && <span className={"state"}>{state.status}</span>}*/}
                </div>
                <div className={"progression"}>
                    {/*{state.completion}*/}
                </div>
                {/*<div className={"detail d-mobile-none"}>{props.description}</div>*/}
            </div>
        </div>
    )
}

SponsorshipBlock.defaultProps = {
    firstname : "Romain",
    lastname : "Vitry",
    email : "romain.vitry@esterlaw.io",
    phone : "0102030405",
    // showNotification : true
}

export default SponsorshipBlock