import React, {Suspense, useEffect, useState} from "react";
import {Await, Link, useLoaderData} from "react-router-dom";

import {useForm} from "react-hook-form";

import {GenericEmptyItem} from "../../other/GenericBlock";
import FilterButtonBar from "../../other/FilterButtonBar";

import Api from "../../helper/api";
import {PROFIL_TYPE_RECRUTEUR} from "../../other/Constant";
import moment from "moment";

const SubscriptionInfo = ({subscription}) => {
    const [showPauseConfirmation, setShowPauseConfirmation] = useState(false)
    const [subscriptionPaused, setSubscriptionPaused] = useState(subscription?.paused)
    const api = new Api()

    const pauseSubscription = () => {
        api.pauseSubscription().then(response => {
            if (response.ok) {
                setSubscriptionPaused(true)
            }
        })
    }

    const resumeSubscription = () => {
        api.resumeSubscription().then(response => {
            if (response.ok) {
                setSubscriptionPaused(false)
            }
        })
    }

    return (
        <>
            <h1>Vous avez souscrit à l'abonnement {subscription.interval === 0 ? 'mensuel' : 'annuel'} {subscription.subscription.name}</h1>
            {/*http://localhost:3000/abonnement/confirmation?payment_intent=pi_3QD8rIKnO1D0RDb50nTO3ilg&payment_intent_client_secret=pi_3QD8rIKnO1D0RDb50nTO3ilg_secret_aakDfzaoV60vC9DPbz429mFil&redirect_status=succeeded*/}
            <div className={"subscription_state"}>
                {subscriptionPaused && <p className={"subscription_state_text"}>Abonnement INACTIF</p>}
                {!subscriptionPaused && <p className={"subscription_state_text"}>Abonnement ACTIF</p>}
                <button className={"cta small blue reverse"} onClick={() => {
                    if (subscriptionPaused) {
                        resumeSubscription()
                    } else {
                        pauseSubscription()
                        // showPauseConfirmation(true);
                    }
                }}>{subscriptionPaused ? 'Reprendre' : 'Pause'}</button>
            </div>
            <div className={"subscription_action"}>
                <div className={"subscription_info"}>
                    {subscription.interval === 0 && <p className={"subscription_info_text"}><span>{subscription.subscription.monthlyAmount}</span>€/mois</p>}
                    {subscription.interval === 1 && <p className={"subscription_info_text"}><span>{subscription.subscription.yearlyAmount}</span>€/an</p>}
                    {!subscriptionPaused && <>
                        <p className={"subscription_info_text small"}>Le renouvellement {subscription.interval === 0 ? 'mensuel' : 'annuel'} se fera le {moment(subscription.date).format('DD/MM/Y')}</p>
                    </>}
                    {subscriptionPaused && <p className={"subscription_info_text"}>En pause</p>}
                </div>
                <div className={"cta_wrapper"}>
                    <Link className={"cta blue full"} to={"/abonnements"}>Gérer mon abonnement</Link>
                </div>
            </div>
        </>
    )
}

/**
 * Global component
 * @returns {Element}
 * @constructor
 */
const SubscriptionDashboard = () => {
    const [subscription, setSubscription] = useState(null)
    const api = new Api();


    useEffect(() => {
        const fetchData = async () => {
            api.getSubscriptionInfo().then(data => {
                setSubscription(data)
            }).catch(error => {
                console.log(error, 'NO INFO')
            })
        }
        fetchData()
    }, [])

    return (
        <div id={"membership_wrapper"} className={"profile-wrapper"}>
            {subscription && <SubscriptionInfo subscription={subscription}/>}
        </div>
    )
}

export default SubscriptionDashboard