import React, {Suspense, useEffect, useState} from "react";
import {Await, useLoaderData, useOutletContext} from "react-router-dom";
import {Link} from "react-router-dom";
import {useSetState} from "react-use";
import * as Constant from "../../other/Constant";
import {sortMissionByStatus} from "../../helper/missionHelper";

const ProfileDashboard = ({missions}) => {
    const {user} = useOutletContext();
    const [missionsState, setMissionsState] = useSetState() //mission listings

    useEffect(() => {
        console.log(missions, 'LOAD MISSIONS')
        // setMissionsState(sortMissionByStatus(missions))
    }, [missions]);

    return(
        <div className={"profile-container profile_dashboard_container"}>
            <h1>Voici ce qu'il se passe pour vous, {user.firstname}</h1>
            <div className={"profile_dashboard_wrapper"}>
                <div className={"side-left"}>
                    <div className={"profile-dashboard-stat"}>
                        <div className={"bloc_title_info"}>
                            {/*<div className={"profile-dashboard-stat-number"}>{missionsState.published ? missionsState.published.length : 0}</div>*/}
                            <div className={"profile-dashboard-stat-text"}>mission publiées</div>
                        </div>
                    </div>
                    <div className={"profile-dashboard-stat"}>
                        <div className={"bloc_title_info"}>
                            {/*<div className={"profile-dashboard-stat-number"}>{missionsState.published ? missionsState.published.length : 0}</div>*/}
                            <div className={"profile-dashboard-stat-text"}>mission réalisées</div>
                        </div>
                    </div>
                    <div className={"profile-dashboard-stat"}>
                        <div className={"bloc_title_info"}>
                            <div className={"profile-dashboard-stat-number"}>8</div>
                            <div className={"profile-dashboard-stat-text"}>membres</div>
                        </div>
                    </div>
                    <div className={"profile-dashboard-stat"}>
                        <div className={"bloc_title_info"}>
                            {/*<div className={"profile-dashboard-stat-number"}>{missionsState.published ? missionsState.published.length : 0}</div>*/}
                            <div className={"profile-dashboard-stat-text"}>mission en cours</div>
                        </div>
                    </div>
                </div>
                <div className={"side-right"}>
                    <div className={"dashboard_block_wrapper"}>
                        <h2>Vos missions</h2>
                        <Link to={"/missions"} className={"dashboard_block_more_link"}>Voir</Link>
                    </div>
                    <div className={"dashboard_block_wrapper"}>
                        <h2>Vos aides</h2>
                        <Link to={"/missions"} className={"dashboard_block_more_link"}>Voir</Link>
                    </div>
                    <div className={"dashboard_block_wrapper"}>
                        <h2>Vos échanges de permanence</h2>
                        <Link to={"/missions"} className={"dashboard_block_more_link"}>Voir</Link>
                    </div>
                    <div className={"dashboard_block_wrapper"}>
                        <h2>Vos questions juridiques</h2>
                        <Link to={"/missions"} className={"dashboard_block_more_link"}>Voir</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileDashboard
