import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useOutletContext, useRevalidator} from "react-router-dom";

import SlidingPanel from "../_sliding_panel";
import {GenericEmptyItem} from "../../other/GenericBlock";
import {useSetState} from "react-use";
import {ActBlock} from "./_ActBlock";
import AddModelAct from "../../slidingContent/AddModelAct";

const Acts = () => {

    const {acts} = useLoaderData()
    const {user} = useOutletContext();
    const [showSlidingPanel, setShowSlidingPanel] = useState(false);
    const [actState, setActState] = useSetState()
    const revalidator = useRevalidator()

    useEffect(() => {
        acts.then((acts) => setActState({acts}))

    }, [acts]);

    return(
        <div id={"acts_wrapper"}>
            <div className={"header"}>

                <div className={"text-center"}>
                    <button className={"cta full blue"} onClick={() => setShowSlidingPanel(true)}>Ajouter un modèle d'acte</button>
                </div>
            </div>

            <div id={"switch_current"} className={"filter_container"}>

                <div className={"bloc_title_info"}>
                    <p>Retrouvez ici toutes les demandes d'échange de permanence de la communauté autour de vous.</p>
                </div>

                <div className={"switch_block_wrapper"}>

                    <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                        <Await resolve={acts}>
                            {(acts) => {
                                if (acts.length > 0)
                                    return (acts.map((s, key) => <ActBlock {...s} key={`act_${s.id}`} />))
                                else
                                    return (<GenericEmptyItem title={"Aucune demande pour le moment"}/>)
                            }}
                        </Await>
                    </Suspense>

                </div>
            </div>

            <SlidingPanel show={showSlidingPanel} handleClose={() => setShowSlidingPanel(false)} forceSliding={true}>
                <AddModelAct user={user} handleClose={() => {
                    revalidator.revalidate()
                    setShowSlidingPanel(false)
                }}/>
            </SlidingPanel>

        </div>
    )
}

export default Acts