import React, {useState} from "react";

const PasswordInput = ({name, id, placeholder, register}) => {

    //handle multiselect change
    const [showPassword, setShowPassword] = useState(false)

    return (
        <div className={"inputPassword"}>
            <input type={showPassword?"text":"password"} name={name} id={id} placeholder={placeholder} {...register(name, {required: "Veuillez renseigner un mot de passe"})}/>
            <span className={showPassword?"show":"hide"} onClick={() => setShowPassword(!showPassword)}/>
        </div>
    )
}

export default PasswordInput

PasswordInput.defaultProps = {
    name: "password",
    id: "password",
    placeholder : "Mot de passe",
    register: () => console.log("register is not set")
}