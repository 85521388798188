import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import ItemLabel from "../../other/ItemLabel";
import {useSetState} from "react-use";
import switchs from "../switch/Switchs";
import {STEP_AGREEMENT, STEP_DONE, STEP_PENDING, STEP_RECIPIENT_ACCEPTED, STEP_SENDER_ACCEPTED} from "../../other/Constant";

const MissionBlock = (props) => {

    const navigate = useNavigate();
    const [state, setState] = useSetState({
        completion : "",
        status : "..."
    })

    useEffect(() => {

        console.log(props)

        let maxLinkingStep = 0
        if(props.linkings){
            props.linkings.forEach((l) => {
                if(l.step > maxLinkingStep)
                    maxLinkingStep = l.step
            })
        }
        switch(maxLinkingStep){

            case STEP_PENDING :
                setState({completion : "", status : ""})
                break;

            case STEP_RECIPIENT_ACCEPTED :
                setState({completion : "25%", status : "Matching en attente"})
                break;

            case STEP_SENDER_ACCEPTED :
                setState({completion : "50%", status : "En discussion"})
                break;

            case STEP_AGREEMENT :
                setState({completion : "75%", status : "Accord"})
                break;

            case STEP_DONE :
                setState({completion : "100%", status : "Terminée"})
                break;

            default :
                setState({completion : "", status : ""})
        }
    },[])

    return(
        <div className={`mission_block_item`} onClick={() => {
            if(props.link)
                navigate(props.link, { state: { title: props.title }})
            else
                navigate(`/missions/${props.id}`)
        }}>

            {props.type.code && <ItemLabel code={props.type.code} type={props.type.name}/>}
            <div className={"content"}>
                <div className={"title"}>{props.title}</div>
                <div className={"description"}>
                    <span className={"reference"}>{props.reference}</span>
                    {state.status !== "" && <span className={"state"}>{state.status}</span>}
                </div>
                <div className={"progression"}>
                    {state.completion}
                </div>
                <div className={"detail d-mobile-none"}>{props.description}</div>
            </div>
        </div>
    )
}

MissionBlock.defaultProps = {
    createdOn : "1970-01-01 00:00:00",
    title : "Default title",
    description : "Default body",
    status : "",
    unread_match : "0",
    people : "0",
    estimatedDuration : {name : "NA"},
    limitDate : "NA",
    notification : "",
    showCta : true,
    showNotification : true
}

export default MissionBlock