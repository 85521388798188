import React, {useEffect, useState, Suspense} from "react";
import {Await, useLoaderData, useOutletContext} from "react-router-dom";

import {GenericEmptyItem} from "../../other/GenericBlock";
import SlidingPanel from "../_sliding_panel";
import SingleItemHeader, {OtherSingleItemHeader} from "../_singleItem/SingleItemHeader";
import SingleItemMatch from "../_singleItem/SingleItemMatch";
import SingleItemDetails from "../_singleItem/SingleItemDetails";
import {CtaReturn} from "../../other/Cta";

const SingleSwitch = () => {

    //state
    const [switchDuty, setSwitchDuty] = useState([])
    /*const [userSwitch, setUserSwitch] = useState(false)*/
    const [pendingMatchs, setPendingMatchs] = useState(0)
    const [acceptedMatchs, setAcceptedMatchs] = useState(0)

    //filter
    const [showSlidingPanelDetails, setShowSlidingPanelDetails] = useState(false);

    //loaders
    const {switchPromise} = useLoaderData()

    //context
    const {user} = useOutletContext()

    useEffect(() => {
        switchPromise.then((switchDuty) => {

            let pendingMatchCount = 0
            let acceptedMatchCount = 0

            switchDuty.linkings.forEach((l) => {

                if(l.step === 1)
                    pendingMatchCount++
                else if(l.step === 3)
                    acceptedMatchCount++
            })

            setPendingMatchs(pendingMatchCount)
            setAcceptedMatchs(acceptedMatchCount)

            //switch is current user help
            /*setUserSwitch(user.id === switchDuty.user.id)
            setSwitchDuty(switchDuty)*/
        })
    })

    return(
        <div className={"single_item_wrapper"} id={"single_help_wrapper"}>

            <Suspense fallback={<GenericEmptyItem title={"Chargement en cours ..."}/>}>
                <Await resolve={switchPromise}>
                    {(switchDuty) =>{

                        setSwitchDuty(switchDuty)

                        if(user.id === switchDuty.user.id) {
                            return(
                                <>
                                    <SingleItemHeader singleItem={switchDuty} setShowSlidingPanelDetails={setShowSlidingPanelDetails}/>
                                    <SingleItemMatch pendingMatchs={pendingMatchs} acceptedMatchs={acceptedMatchs} itemId={switchDuty.id} type={"switch"}/>

                                    <div id={"single_switch_cta"} className={"single_item_cta_wrapper"}>
                                        <button className={"cta blue full"}>Cet échange est terminé</button>
                                        <button className={"cta full"}>Supprimer</button>
                                    </div>
                                </>
                            )
                        }else {
                            return(
                                <>
                                    <OtherSingleItemHeader singleItem={switchDuty}/>
                                    <div id={"single_switch_cta"} className={"single_item_cta_wrapper"}>
                                        <button className={"cta blue full"}>Candidater</button>
                                    </div>
                                </>
                            )
                        }
                    }}
                </Await>
            </Suspense>

            <SlidingPanel show={showSlidingPanelDetails} handleClose={() => setShowSlidingPanelDetails(false)} forceSliding={true}>
                <SingleItemDetails singleItem={switchDuty}/>
            </SlidingPanel>

        </div>
    )
}

export default SingleSwitch