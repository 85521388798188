import React, {useState} from "react";
import {useForm} from "react-hook-form";

const JoinCompany = ({company, handleClose}) => {

    const {register,formState: { errors }, handleSubmit, reset, setValue} = useForm();

    const handleFormSubmit = (data) => {
        handleClose()
    }

    return (
        <div id={"create_company_wrapper"}>
            <div className={"bloc_title_info"}>
                <h2>Cabinet</h2>
                <p>Je choisis ce cabinet ?</p>
            </div>
            <form onSubmit={handleSubmit(handleFormSubmit)} className={"profil_block"}>
                <div className={"form_row_wrapper type3"}>
                    <label>Siret</label>
                    <input type={"text"} name={"siret"} id={"siret"} disabled value={company?.registrationNumber} />
                </div>
                <div className={"form_row_wrapper type3"}>
                    <label>Nom</label>
                    <input type={"text"} name={"name"} id={"name"} disabled value={company?.name} />
                </div>
                <div className={"form_row_wrapper type3"}>
                    <label>Adresse</label>
                    <input type={"text"} name={"address"} id={"address"} disabled value={company?.location} />
                </div>
                <div className={"cta_wrapper"}>
                    <button className={"cta blue full"}>Confirmer</button>
                </div>
            </form>
        </div>
    )
}

export default JoinCompany