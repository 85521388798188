import React, {useEffect, useRef, useState} from "react";

// forceUpdate hook
export function useForceUpdate() {
    let [value, setState] = useState(true);
    return () => setState(!value);
}

//ComponentDidUpdate Hook
export function useDidUpdate (callback, deps) {
    const hasMount = useRef(false)

    useEffect(() => {
        if (hasMount.current) {
            callback()
        } else {
            hasMount.current = true
        }
    }, deps)
}